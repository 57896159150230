import * as Ariakit from '@ariakit/react'
import React from 'react'

import {cn} from '../../utils'
import {tailwindConfig} from '@cheddarup/tailwind-config'

export interface TextProps extends Ariakit.RoleProps<'span'> {}

export const Text = React.forwardRef<HTMLSpanElement, TextProps>(
  ({className, ...restProps}, forwardedRef) => {
    return (
      <Ariakit.Role.span
        ref={forwardedRef}
        className={cn(getAntialiasClassName(className), className)}
        {...restProps}
      />
    )
  },
)

// MARK: – Helpers

const fontFamilyClassNames = Object.keys(tailwindConfig.theme.fontFamily).map(
  (fontFamilyKey) => `font-${fontFamilyKey}`,
)

export function getAntialiasClassName(
  ...classNames: Array<string | undefined>
) {
  const lastFontFamilyClassName = classNames
    .flatMap((className) => className?.split(' '))
    .reverse()
    .find(
      (className) =>
        className != null && fontFamilyClassNames.includes(className),
    )

  // GlamourAbsolute does not look good with subpixel antialiase
  if (lastFontFamilyClassName === 'font-accentAlt') {
    return 'antialiased'
  }

  return undefined
}
