import * as WebUI from '@cheddarup/web-ui'
import {forwardRef, useEffect, useImperativeHandle, useRef} from 'react'
import {api, useUpdateUserMutation} from '@cheddarup/api-client'
import {useLiveRef} from '@cheddarup/react-util'

import {AbsoluteLinkButton, LinkButtonProps} from '../LinkButton'
import {SideNavNavLinkButton} from '../SideNavNavLinkButton'
import {useManagerRole, useManagerRoleId} from '../ManageRoleProvider'
import config from 'src/config'

export interface CollectionsSidebarInstance {
  showFinishSetup: () => void
}

export const CollectionsSidebar = forwardRef<
  CollectionsSidebarInstance,
  WebUI.SideNavProps
>(({className, ...restProps}, forwardedRef) => {
  const [managerRoleId] = useManagerRoleId()
  const [managerRole] = useManagerRole()
  const rootSideNavRef = useRef<WebUI.DialogInstance>(null)
  const finishSetupMenuRef = useRef<WebUI.DialogInstance>(null)
  const hasExternalAccountsQuery = api.externalAccounts.list.useQuery(
    undefined,
    {
      enabled: !managerRoleId,
      select: (externalAccounts) =>
        [...externalAccounts.cards, ...externalAccounts.banks].length > 0,
    },
  )
  const {data: session} = api.auth.session.useSuspenseQuery()
  const updateUserMutation = useUpdateUserMutation()

  useImperativeHandle(forwardedRef, () => ({
    showFinishSetup: () => {
      rootSideNavRef.current?.show()
      requestAnimationFrame(() => finishSetupMenuRef.current?.show())
    },
  }))

  const isUpdatingUserRef = useLiveRef(updateUserMutation.isPending)
  const isCustomCardDescriptorComplete =
    !!session.user.profile.uiClientFlags?.onboardingChecklist
      ?.statementDescriptorComplete ||
    (!!session.user.profile.uiClientFlags?.customCardDescriptor &&
      session.user.profile.uiClientFlags?.customCardDescriptor !==
        session.user.display_name?.slice(0, 17))

  const updateUser = updateUserMutation.mutate
  useEffect(() => {
    const onboardingChecklist =
      session.user.profile.uiClientFlags?.onboardingChecklist

    if (
      !isUpdatingUserRef.current &&
      !session.user.editable &&
      isCustomCardDescriptorComplete &&
      !onboardingChecklist?.finishSetupComplete &&
      hasExternalAccountsQuery.data &&
      onboardingChecklist?.overviewWatched &&
      onboardingChecklist?.appDownloaded
    ) {
      updateUser({
        body: {
          profile: {
            uiClientFlags: {
              onboardingChecklist: {
                finishSetupComplete: true,
              },
            },
          },
        },
      })
    }
  }, [
    hasExternalAccountsQuery.data,
    isCustomCardDescriptorComplete,
    session.user.editable,
    session.user.profile.uiClientFlags?.onboardingChecklist,
    updateUser,
  ])

  const feedbackUrl = session.upvoty?.token
    ? `https://feedback.cheddarup.com?custom_sso_token=${session.upvoty.token}`
    : 'https://feedback.cheddarup.com'

  return (
    <WebUI.SideNav
      ref={rootSideNavRef}
      className={WebUI.cn(
        '[&_.Button-iconBefore]:text-[28px] [&_.Button-iconBefore]:text-teal-50',
        className,
      )}
      disclosure={
        <WebUI.DialogDisclosure as={WebUI.IconButton}>
          <WebUI.PhosphorIcon width={30} icon="list" />
          {(!session.user.profile.uiClientFlags?.onboardingChecklist
            ?.finishSetupComplete ||
            session.user.editable) && (
            <WebUI.PhosphorIcon
              className="absolute top-0 right-0 text-orange-500"
              width={12}
              icon="warning-circle-fill"
            />
          )}
        </WebUI.DialogDisclosure>
      }
      {...restProps}
    >
      {(dialog) => (
        <>
          <SideNavNavLinkButton
            iconBefore={<WebUI.PhosphorIcon icon="briefcase" />}
            to="/collections"
          >
            Collections
          </SideNavNavLinkButton>
          <WebUI.Separator />
          {(!managerRole || managerRole.permissions.role === 'admin') && (
            <>
              <SideNavNavLinkButton
                iconBefore={<WebUI.PhosphorIcon icon="user-circle-plus" />}
                to="/managers"
              >
                Managers
              </SideNavNavLinkButton>
              <WebUI.Separator />
            </>
          )}
          {(!managerRole || managerRole.permissions.group_page) && (
            <>
              <SideNavNavLinkButton
                iconBefore={<WebUI.PhosphorIcon icon="link" />}
                to="/group"
              >
                Group Page
              </SideNavNavLinkButton>
              <WebUI.Separator />
            </>
          )}
          <SideNavNavLinkButton
            iconBefore={<WebUI.PhosphorIcon icon="chart-bar" />}
            to="/reports"
          >
            Reports
          </SideNavNavLinkButton>
          <WebUI.Separator />
          {session?.organization_data?.internalMarketplace?.enabled && (
            <>
              <SideNavNavLinkButton
                iconBefore={<WebUI.PhosphorIcon icon="magnifying-glass" />}
                to="/marketplace/shop-items"
                target="_blank"
              >
                Search All{' '}
                {
                  session.organization_data.internalMarketplace
                    .organizerNickname
                }{' '}
                Sales
              </SideNavNavLinkButton>
              <WebUI.Separator />
            </>
          )}
          <WebUI.SideNav
            className="[&_>_.SideSheetContentView]:shadow-none"
            backdropClassName="top-16"
            disclosure={
              <WebUI.DialogDisclosure
                as={WebUI.SideNavButton}
                iconBefore={<WebUI.PhosphorIcon icon="question" width={26} />}
                hideDialogOnClick={false}
              >
                Help and Inspiration
              </WebUI.DialogDisclosure>
            }
          >
            <WebUI.SideNavBackButton />
            <WebUI.SideNavButton
              as={WebUI.AnchorButton}
              href="https://www.cheddarup.com/how-does-cheddar-up-work/"
              target="_blank"
              onClick={() => dialog.hide()}
            >
              How Cheddar Up Works
            </WebUI.SideNavButton>
            <WebUI.SideNavButton
              as={WebUI.AnchorButton}
              href="https://www.cheddarup.com/demo/"
              target="_blank"
              onClick={() => dialog.hide()}
            >
              Watch a Demo
            </WebUI.SideNavButton>
            <WebUI.SideNavButton
              as={WebUI.AnchorButton}
              href="https://www.cheddarup.com/learning-sessions/"
              target="_blank"
              onClick={() => dialog.hide()}
            >
              Learning Sessions
            </WebUI.SideNavButton>
            <WebUI.SideNavButton
              as={WebUI.AnchorButton}
              href="https://support.cheddarup.com/hc/en-us"
              target="_blank"
              onClick={() => dialog.hide()}
            >
              Knowledge Center
            </WebUI.SideNavButton>
            <WebUI.SideNavButton
              as={WebUI.AnchorButton}
              href={feedbackUrl}
              target="_blank"
              onClick={() => dialog.hide()}
            >
              Give Feedback
            </WebUI.SideNavButton>
            <WebUI.SideNavButton
              as={WebUI.AnchorButton}
              href="https://www.cheddarup.com/reviews/"
              target="_blank"
              onClick={() => dialog.hide()}
            >
              User Stories
            </WebUI.SideNavButton>
            <WebUI.SideNavButton
              as={WebUI.AnchorButton}
              href="https://www.cheddarup.com/blog/"
              target="_blank"
              onClick={() => dialog.hide()}
            >
              Blog
            </WebUI.SideNavButton>
          </WebUI.SideNav>
          <WebUI.Separator />

          {!managerRoleId && (
            <>
              {!session.user.profile.uiClientFlags?.onboardingChecklist
                ?.finishSetupComplete && (
                <WebUI.SideNav
                  ref={finishSetupMenuRef}
                  className="[&_>_.SideSheetContentView]:shadow-none"
                  backdropClassName="top-16"
                  disclosure={
                    <WebUI.DialogDisclosure
                      as={WebUI.SideNavButton}
                      iconBefore={<WebUI.PhosphorIcon icon="user-circle" />}
                      iconAfter={
                        <WebUI.PhosphorIcon
                          icon="warning-circle-fill"
                          className="text-orange-500"
                        />
                      }
                      hideDialogOnClick={false}
                    >
                      Finish Setup
                    </WebUI.DialogDisclosure>
                  }
                >
                  <div className="flex flex-col">
                    <WebUI.SideNavBackButton />
                    <SideNavNavLinkButton
                      aria-current="page"
                      to="."
                      onClick={(event) => {
                        event.preventDefault()
                        rootSideNavRef.current?.hide()
                      }}
                    >
                      Finish Setup
                    </SideNavNavLinkButton>
                    <WebUI.SideNavButton
                      as={WebUI.Radio}
                      checked
                      hideDialogOnClick={false}
                    >
                      Create your account
                    </WebUI.SideNavButton>
                    <WebUI.SideNavButton
                      as={RadioLinkButton}
                      checked={!session.user.editable}
                      to="my-account/account-details"
                      onClick={() => dialog.hide()}
                    >
                      Complete your details
                    </WebUI.SideNavButton>
                    <WebUI.SideNavButton
                      as={RadioLinkButton}
                      checked={isCustomCardDescriptorComplete}
                      to="my-account/statement-descriptor"
                      onClick={() => dialog.hide()}
                    >
                      Confirm statement descriptor
                    </WebUI.SideNavButton>
                    <WebUI.SideNavButton
                      as={RadioLinkButton}
                      checked={hasExternalAccountsQuery.data}
                      to="my-account/withdrawal-settings"
                      onClick={() => dialog.hide()}
                    >
                      Add a withdrawal method
                    </WebUI.SideNavButton>
                    <WebUI.SideNavButton
                      as={RadioLinkButton}
                      checked={!!session.user.profile_pic}
                      to="my-account/display-name"
                      onClick={() => dialog.hide()}
                    >
                      Upload profile picture
                    </WebUI.SideNavButton>
                    <WebUI.Radio
                      className="h-14 px-5 text-ds-sm"
                      as={WebUI.Anchor}
                      checked={
                        session.user.profile.uiClientFlags?.onboardingChecklist
                          ?.appDownloaded ?? false
                      }
                      href={
                        WebUI.isAppleDevice()
                          ? config.appleStoreLink
                          : config.googlePlayLink
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Download the app
                    </WebUI.Radio>
                  </div>
                </WebUI.SideNav>
              )}
              <WebUI.Separator />
            </>
          )}
        </>
      )}
    </WebUI.SideNav>
  )
})

// MARK: – RadioLinkButton

interface RadioLinkButtonProps
  extends Pick<WebUI.RadioProps, 'checked'>,
    LinkButtonProps,
    React.ComponentPropsWithoutRef<'a'> {}

const RadioLinkButton = forwardRef<HTMLAnchorElement, RadioLinkButtonProps>(
  ({checked, children, ...restProps}, forwardedRef) => (
    <AbsoluteLinkButton ref={forwardedRef} {...restProps}>
      <WebUI.Radio className="text-ds-sm" checked={checked}>
        {children}
      </WebUI.Radio>
    </AbsoluteLinkButton>
  ),
)
