import * as Yup from 'yup'
import {useFormik, useUpdateEffect} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import React, {useContext, useMemo, useState} from 'react'
import {
  Avatar,
  Dialog,
  DialogDisclosure,
  DialogDisclosureProps,
  DialogInstance,
  DialogProps,
  Ellipsis,
  Group,
  GroupLabel,
  Input,
  ModalContent,
  ModalContentProps,
  NextAnchor,
  NextButton,
  Radio,
  RadioGroup,
  SelectedTabUnderline,
  SignatureCanvas,
  SignatureCanvasClearButton,
  SignatureCanvasEmptyStateView,
  SignatureCanvasProvider,
  SignatureCanvasProviderProps,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Text,
} from '@cheddarup/web-ui/next'
import {
  api,
  endpoints,
  InferBody,
  useCreateESignatureMutation,
  useUpdateESignatureMutation,
} from '@cheddarup/api-client'
import {uploadESignature} from '@cheddarup/core'
import {
  useCartPathParams,
  useCreateCartIfNotExists,
} from 'src/views/c/hooks/useCart'
import * as Util from '@cheddarup/util'

export interface ESignatureModalProps
  extends DialogProps,
    Pick<ModalContentProps, 'className' | 'backdropClassName'>,
    Pick<
      ESignatureFormProps,
      | 'readOnly'
      | 'initialValues'
      | 'signatureRequired'
      | 'initialsRequired'
      | 'onESignSubmit'
    > {}

export const ESignatureModal = React.forwardRef<
  DialogInstance,
  ESignatureModalProps
>(
  (
    {
      className,
      backdropClassName,
      readOnly,
      initialValues,
      signatureRequired,
      initialsRequired,
      onESignSubmit,
      ...restProps
    },
    forwardedRef,
  ) => {
    const cartPathParams = useCartPathParams()

    const tabId = cartPathParams.tabId as string | undefined
    const cartUuid = cartPathParams.cartUuid ?? undefined

    const eSignaturesQuery = api.eSignatures.list.useQuery(
      {
        pathParams: {
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          tabId: tabId!,
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          cartUuid: cartUuid!,
        },
      },
      {
        enabled: tabId != null && cartUuid != null,
      },
    )
    const transientESignaturesContextValue = useContext(
      TransientESignaturesContext,
    )

    const eSignatures =
      eSignaturesQuery.data ??
      transientESignaturesContextValue?.transientESignatures

    const firstESignature = useMemo(
      () => eSignatures?.[0] ?? null,
      [eSignatures?.[0]],
    )

    const [selectedESignature, setSelectedESignature] =
      useState<Api.ESignature | null>(firstESignature)

    useUpdateEffect(() => {
      setSelectedESignature((prevSelectedESignature) =>
        firstESignature?.signer_number === prevSelectedESignature?.signer_number
          ? firstESignature
          : (prevSelectedESignature ?? firstESignature ?? null),
      )
    }, [firstESignature])

    return (
      <Dialog ref={forwardedRef} {...restProps}>
        {(dialog) => (
          <ModalContent
            className={WebUI.cn(
              'flex h-full flex-col divide-y divide-depr-grey-200 overflow-hidden sm:max-h-[580px] sm:w-auto sm:flex-row sm:divide-x sm:divide-y-0',
              className,
            )}
            backdropClassName={backdropClassName}
            backdrop={<div />}
          >
            {eSignatures && eSignatures.length > 0 && (
              <SavedESignatures
                className="max-h-full flex-0 sm:max-w-56 sm:overflow-y-auto"
                eSignatures={eSignatures}
                selectedESignatureSignerNumber={
                  selectedESignature?.signer_number ?? null
                }
                onAddNewESignature={() => setSelectedESignature(null)}
                onSelectESignature={(newSelectedESignature) =>
                  setSelectedESignature(newSelectedESignature)
                }
              />
            )}

            <ESignatureForm
              key={selectedESignature?.signer_number}
              className="grow overflow-y-auto sm:w-[512px]"
              tabId={tabId}
              initialValues={initialValues ?? undefined}
              signatureRequired={signatureRequired}
              initialsRequired={initialsRequired}
              eSignature={selectedESignature}
              readOnly={readOnly}
              onCancel={() => dialog.hide()}
              onESignSubmit={(esignature) => {
                onESignSubmit?.(esignature)
                dialog.hide()
              }}
              onTransientESignSubmit={(transientEsignature) => {
                transientESignaturesContextValue?.setTransientESignatures(
                  (prevTransientESignatures) => [
                    ...prevTransientESignatures,
                    transientEsignature,
                  ],
                )

                onESignSubmit?.(transientEsignature)
                dialog.hide()
              }}
            />
          </ModalContent>
        )}
      </Dialog>
    )
  },
)

export interface ESignatureFormValues {
  firstName: string
  lastName: string
  inputMode: Api.ESignatureInputMode
  signatureAsDataURL: string | null
  initialsAsDataURL: string | null
}

export interface ESignatureFormProps
  extends React.ComponentPropsWithoutRef<'form'> {
  tabId: string | null | undefined
  eSignature?: Api.ESignature | null
  initialValues?: Partial<Omit<ESignatureFormValues, 'inputMode'>>
  signatureRequired?: boolean
  initialsRequired?: boolean
  readOnly?: boolean
  onCancel?: () => void
  onESignSubmit?: (values: Api.ESignature) => void
  onTransientESignSubmit?: (err: Api.ESignature) => void
}

export const ESignatureForm = ({
  tabId,
  eSignature,
  initialValues,
  signatureRequired = true,
  initialsRequired = true,
  readOnly,
  onCancel,
  onESignSubmit,
  onTransientESignSubmit,
  className,
  ...restProps
}: ESignatureFormProps) => {
  const media = WebUI.useMedia()
  const createCartIfNotExists = useCreateCartIfNotExists()
  const createESignatureMutation = useCreateESignatureMutation()
  const updateESignatureMutation = useUpdateESignatureMutation()
  const [, setCartUuid] = WebUI.useSessionStorage<string | null>(
    `CART_UUID-${tabId}`,
    null,
  )

  const initialFirstName =
    eSignature?.first_name ?? initialValues?.firstName ?? ''
  const initialLastName = eSignature?.last_name ?? initialValues?.lastName ?? ''

  const formik = useFormik<ESignatureFormValues>({
    initialValues: {
      inputMode:
        eSignature?.input_mode ||
        (initialValues?.signatureAsDataURL == null &&
        initialValues?.initialsAsDataURL == null
          ? 'type'
          : 'draw'),
      firstName: initialFirstName,
      lastName: initialLastName,
      signatureAsDataURL:
        eSignature?.signature_url ?? initialValues?.signatureAsDataURL ?? null,
      initialsAsDataURL:
        eSignature?.initials_url ?? initialValues?.initialsAsDataURL ?? null,
    },
    validationSchema: Yup.object().shape({
      inputMode: Yup.string(),
      firstName: Yup.string().required('Required').trim(),
      lastName: Yup.string().required('Required').trim(),
      signatureAsDataURL: Yup.string()
        .nullable()
        .when(['inputMode'], ([inputMode], schema) =>
          signatureRequired && inputMode === 'draw'
            ? schema.required('Required')
            : schema,
        ),
      initialsAsDataURL: Yup.string()
        .nullable()
        .when(['inputMode'], ([inputMode], schema) =>
          initialsRequired && inputMode === 'draw'
            ? schema.required('Required')
            : schema,
        ),
    }),
    onSubmit: async (values, formikHelpers) => {
      let signatureAsDataURL = values.signatureAsDataURL
      let initialsAsDataURL = values.initialsAsDataURL

      if (values.inputMode === 'type') {
        const options = {
          font: '400 34px dancing-script, "Brush Script MT", cursive',
        }

        signatureAsDataURL = WebUI.makeCanvasFromText(
          extractSignatureFromName(values.firstName, values.lastName),
          options,
        ).toDataURL()
        initialsAsDataURL = WebUI.makeCanvasFromText(
          extractInitialsFromName(values.firstName, values.lastName),
          options,
        ).toDataURL()
      }

      if (signatureAsDataURL || initialsAsDataURL) {
        if (tabId) {
          const cartUuid = await createCartIfNotExists()
          const [signatureUrl, initialsUrl] = await Promise.all([
            signatureAsDataURL
              ? uploadESignature(signatureAsDataURL, {tabId, cartUuid})
              : null,
            initialsAsDataURL
              ? uploadESignature(initialsAsDataURL, {tabId, cartUuid})
              : null,
          ])

          const body = {
            first_name: values.firstName,
            last_name: values.lastName,
            signature_url: signatureUrl,
            initials_url: initialsUrl,
            device_info: navigator.userAgent,
            input_mode: values.inputMode,
          }

          if (eSignature) {
            const updatedESignature =
              await updateESignatureMutation.mutateAsync({
                pathParams: {
                  tabId,
                  cartUuid,
                  signerNumber: eSignature.signer_number,
                },
                body,
              })

            onESignSubmit?.(updatedESignature)
          } else {
            const createdESignature =
              await createESignatureMutation.mutateAsync({
                pathParams: {
                  tabId,
                  cartUuid,
                },
                body,
              })

            setCartUuid(cartUuid)

            onESignSubmit?.(createdESignature)
          }
        } else {
          onTransientESignSubmit?.(
            makeTransientESignature({
              signature_url: signatureAsDataURL,
              initials_url: initialsAsDataURL,
              first_name: values.firstName,
              last_name: values.lastName,
              input_mode: values.inputMode,
            }),
          )
        }
      }

      formikHelpers.resetForm()
    },
  })

  return (
    <form
      className={WebUI.cn('flex flex-col gap-4 sm:gap-0', className)}
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...restProps}
    >
      <Tabs
        selectedId={formik.values.inputMode}
        setSelectedId={(newSelectedId) =>
          formik.setFieldValue('inputMode', newSelectedId)
        }
      >
        {(tabs) => {
          const tabList = (
            <TabList
              className="border-0 px-2 sm:border-b sm:px-6"
              disabled={readOnly}
            >
              <Tab
                id="type"
                disabled={tabs.getState().selectedId !== 'type' && readOnly}
              >
                Type
              </Tab>
              <Tab
                id="draw"
                disabled={tabs.getState().selectedId !== 'draw' && readOnly}
              >
                Draw
              </Tab>

              <SelectedTabUnderline />
            </TabList>
          )

          return (
            <>
              <div className="flex flex-row items-center justify-between gap-3 border-b px-6 sm:border-b-0 sm:pt-6 sm:pb-2">
                <WebUI.Heading className="grow font-extrabold" as="h3">
                  {media.sm ? 'Signature & Initials' : 'Sign'}
                </WebUI.Heading>
                {!media.sm && tabList}
              </div>

              <div className="flex grow flex-col gap-4">
                <WebUI.FormFieldGroup className="px-6">
                  <WebUI.FormField
                    label="First name"
                    error={formik.errors.firstName}
                    required
                  >
                    <Input
                      name="firstName"
                      autoComplete="given-name"
                      placeholder="First name"
                      readOnly={readOnly || !!eSignature}
                      value={formik.values.firstName}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </WebUI.FormField>
                  <WebUI.FormField
                    label="Last name"
                    error={formik.errors.lastName}
                    required
                  >
                    <Input
                      name="lastName"
                      autoComplete="family-name"
                      placeholder="Last name"
                      readOnly={readOnly || !!eSignature}
                      value={formik.values.lastName}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </WebUI.FormField>
                </WebUI.FormFieldGroup>

                <div className="flex grow flex-col gap-6">
                  {media.sm && tabList}

                  <div className="flex grow flex-col gap-4 px-6">
                    <TabPanel
                      className="flex flex-col gap-4 text-depr-grey-700 sm:flex-row"
                      tabId="type"
                    >
                      <WebUI.FormField className="basis-2/3">
                        <Input
                          className="h-[120px] bg-[#F7F7F7] px-4 py-3 font-cursive text-[34px]"
                          name="signatureAsText"
                          autoComplete="name"
                          autoCapitalize="words"
                          readOnly
                          variant="headless"
                          value={extractSignatureFromName(
                            formik.values.firstName,
                            formik.values.lastName,
                          )}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </WebUI.FormField>
                      <WebUI.FormField className="basis-1/3">
                        <WebUI.InitialsInput
                          className="h-[120px] bg-[#F7F7F7] px-4 py-3 text-[34px]"
                          name="initialsAsText"
                          variant="headless"
                          readOnly
                          value={extractInitialsFromName(
                            formik.values.firstName,
                            formik.values.lastName,
                          )}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </WebUI.FormField>
                    </TabPanel>
                    <TabPanel
                      className="flex flex-col gap-4 sm:flex-row"
                      tabId="draw"
                    >
                      <WebUI.FormField
                        className="basis-2/3"
                        required
                        error={formik.errors.signatureAsDataURL}
                      >
                        <ESignatureCanvas
                          readOnly={readOnly}
                          defaultDataURL={
                            formik.initialValues.signatureAsDataURL ?? undefined
                          }
                          onDataURLChange={(newDataURL) =>
                            formik.setFieldValue(
                              'signatureAsDataURL',
                              newDataURL,
                            )
                          }
                        />
                      </WebUI.FormField>
                      <WebUI.FormField
                        className="basis-1/3"
                        required
                        error={formik.errors.initialsAsDataURL}
                      >
                        <ESignatureCanvas
                          readOnly={readOnly}
                          defaultDataURL={
                            formik.initialValues.initialsAsDataURL ?? undefined
                          }
                          onDataURLChange={(newDataURL) =>
                            formik.setFieldValue(
                              'initialsAsDataURL',
                              newDataURL,
                            )
                          }
                        />
                      </WebUI.FormField>
                    </TabPanel>

                    <span className="font-normal text-ds-xs text-grey-500">
                      By signing electronically, I acknowledge my digital
                      signature and initials are legally equivalent to my
                      handwritten ones on all documents, including contracts.
                      {'\n'}
                      <NextAnchor
                        target="_blank"
                        href="https://www.cheddarup.com/electronic-record-and-signature-disclosure/"
                      >
                        View Electronic Record and Signature Disclosure
                      </NextAnchor>
                    </span>
                  </div>
                </div>

                <div className="flex flex-row gap-4 border-depr-grey-200 border-t p-6">
                  <NextButton
                    loading={formik.isSubmitting}
                    type="submit"
                    variant="orange"
                    roundness="pill"
                  >
                    Accept and Sign
                  </NextButton>
                  <NextButton
                    type="button"
                    variant="gray"
                    roundness="pill"
                    onClick={() => onCancel?.()}
                  >
                    Cancel
                  </NextButton>
                </div>
              </div>
            </>
          )
        }}
      </Tabs>
    </form>
  )
}

// MARK: – ESignatureModalDisclosure

export interface ESignatureModalDisclosureProps
  extends Omit<DialogDisclosureProps, 'disclosure' | 'children'> {
  mode: 'signature' | 'initials'
  eSignatureSignerNumber?: string | null
  placeholder?: string
}

export const ESignatureModalDisclosure = React.forwardRef<
  HTMLButtonElement,
  ESignatureModalDisclosureProps
>(
  (
    {mode, eSignatureSignerNumber, placeholder, className, ...restProps},
    forwardedRef,
  ) => {
    const isSignerNumberUrl = Util.isUrl(eSignatureSignerNumber ?? '')
    const cartPathParams = useCartPathParams()
    const eSignatureQuery = api.eSignatures.list.useQuery(
      {
        pathParams: {
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          tabId: cartPathParams.tabId!,
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          cartUuid: cartPathParams.cartUuid!,
        },
      },
      {
        enabled:
          !!cartPathParams.tabId &&
          !!cartPathParams.cartUuid &&
          !isSignerNumberUrl,
        select: (eSignatures) =>
          eSignatures.find(
            (eSignature) => eSignature.signer_number === eSignatureSignerNumber,
          ),
      },
    )
    const transientESignaturesContextValue = useContext(
      TransientESignaturesContext,
    )

    const transientESignature =
      transientESignaturesContextValue?.transientESignatures.find(
        (eSignature) => eSignature.signer_number === eSignatureSignerNumber,
      )

    const eSignature = eSignatureQuery.data ?? transientESignature

    const imageUrl = isSignerNumberUrl
      ? eSignatureSignerNumber
      : mode === 'signature'
        ? eSignature?.signature_url
        : eSignature?.initials_url

    return (
      <DialogDisclosure
        ref={forwardedRef}
        className={WebUI.cn(
          'h-18 justify-start rounded border bg-trueWhite px-4',
          'hover:[&:not([aria-disabled=true])]:bg-inputHoverBackground',
          imageUrl ? 'text-depr-grey-700' : 'text-depr-grey-400 text-ds-base',
          isSignerNumberUrl && 'contents',
          className,
        )}
        type="button"
        render={
          <NextButton size="headless" variant="headless">
            {imageUrl ? (
              <WebUI.Image
                className="object-contain"
                height={32}
                alt=""
                src={imageUrl}
              />
            ) : (
              placeholder
            )}
          </NextButton>
        }
        {...restProps}
      />
    )
  },
)

// MARK: – ESignatureCanvas

interface ESignatureCanvasProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Pick<
      SignatureCanvasProviderProps,
      'readOnly' | 'defaultDataURL' | 'onDataURLChange'
    > {}

const ESignatureCanvas = ({
  'aria-invalid': ariaInvalid,
  defaultDataURL,
  onDataURLChange,
  className,
  readOnly,
  ...restProps
}: ESignatureCanvasProps) => {
  return (
    <div
      aria-readonly={readOnly}
      className={WebUI.cn(
        'relative h-[120px] rounded bg-[#F7F7F7] aria-readonly:px-4 aria-readonly:py-3',
        className,
      )}
      {...restProps}
    >
      <SignatureCanvasProvider
        readOnly={readOnly}
        defaultDataURL={defaultDataURL}
        onDataURLChange={onDataURLChange}
      >
        <SignatureCanvas className="h-full" aria-invalid={ariaInvalid} />
        <SignatureCanvasEmptyStateView>
          <span className="text-center text-depr-grey-400 text-ds-md">
            Draw here
          </span>
        </SignatureCanvasEmptyStateView>
        {!readOnly && (
          <SignatureCanvasClearButton className="absolute top-2 right-2" />
        )}
      </SignatureCanvasProvider>
    </div>
  )
}

// MARK: – SavedESignatures

export interface SavedESignaturesProps
  extends React.ComponentPropsWithoutRef<'div'> {
  eSignatures: Api.ESignature[]
  selectedESignatureSignerNumber: string | null
  onAddNewESignature?: () => void
  onSelectESignature?: (eSignature: Api.ESignature) => void
}

export const SavedESignatures = ({
  eSignatures,
  selectedESignatureSignerNumber,
  onAddNewESignature,
  onSelectESignature,
  className,
  ...restProps
}: SavedESignaturesProps) => {
  const media = WebUI.useMedia()

  return (
    <div
      className={WebUI.cn(
        'flex flex-col gap-6 bg-trueWhite pt-6 pb-4 sm:gap-8 sm:pb-6',
        className,
      )}
      {...restProps}
    >
      {media.sm && (
        <NextButton
          className="mx-6 flex-0"
          roundness="pill"
          onClick={() => onAddNewESignature?.()}
        >
          <WebUI.PhosphorIcon icon="plus" />
          New signature
        </NextButton>
      )}

      <Group className="flex flex-0 flex-col gap-4">
        <GroupLabel className="mx-6 text-ds-xs text-grey-500">
          SAVED SIGNATURES
        </GroupLabel>
        <RadioGroup
          className="scrollbar-hide flex-row overflow-x-auto px-6 sm:flex-col sm:overflow-x-hidden"
          value={selectedESignatureSignerNumber}
          setValue={(newValue) => {
            const newSelectedESignature = eSignatures.find(
              (v) => v.signer_number === newValue,
            )
            if (newSelectedESignature) {
              onSelectESignature?.(newSelectedESignature)
            }
          }}
        >
          {eSignatures.map((eSignature) => {
            const name =
              `${eSignature.first_name} ${eSignature.last_name}`.trim()

            return (
              <Radio
                key={eSignature.signer_number}
                render={
                  media.sm ? (
                    <Ellipsis className="text-grey-500 aria-checked:font-medium aria-checked:text-orange-600">
                      {name}
                    </Ellipsis>
                  ) : (
                    <Avatar
                      className="flex-0 border-2 aria-checked:border-teal-600"
                      size="xl"
                      name={name}
                    />
                  )
                }
                variant="headless"
                size="headless"
                value={eSignature.signer_number}
              />
            )
          })}
        </RadioGroup>
      </Group>

      {!media.sm && (
        <Text className="mx-6 font-semibold text-grey-800">
          New signer?{' '}
          <NextAnchor
            className="flex-0"
            render={
              <NextButton
                variant="headless"
                size="headless"
                roundness="headless"
                onClick={() => onAddNewESignature?.()}
              />
            }
          >
            Create new signature
          </NextAnchor>
        </Text>
      )}
    </div>
  )
}

// MARK: – TransientESignaturesContext

interface TransientESignaturesContextValue {
  transientESignatures: Api.ESignature[]
  setTransientESignatures: React.Dispatch<
    React.SetStateAction<Api.ESignature[]>
  >
}

const TransientESignaturesContext =
  React.createContext<TransientESignaturesContextValue | null>(null)

export interface TransientESignaturesContextProviderProps {
  children: React.ReactNode
}

export const TransientESignaturesContextProvider = ({
  children,
}: TransientESignaturesContextProviderProps) => {
  const [transientESignatures, setTransientESignatures] = useState<
    Api.ESignature[]
  >([])

  const contextValue: TransientESignaturesContextValue = useMemo(
    () => ({
      transientESignatures,
      setTransientESignatures,
    }),
    [transientESignatures],
  )

  return (
    <TransientESignaturesContext.Provider value={contextValue}>
      {children}
    </TransientESignaturesContext.Provider>
  )
}

// MARK: – Helpers

export function makeTransientESignature(
  body: Omit<InferBody<typeof endpoints.eSignatures.create>, 'device_info'>,
  overrides?: Partial<Api.ESignature>,
): Api.ESignature {
  const createdAt = new Date().toISOString()

  return {
    ...body,
    signer_number: Util.makeShortId(),
    device_info: navigator.userAgent,
    ip: 'unknown',
    status: 'pending',
    created_at: createdAt,
    updated_at: createdAt,
    ...overrides,
  }
}

function extractSignatureFromName(firstName: string, lastName: string) {
  return `${firstName} ${lastName}`.trim()
}

function extractInitialsFromName(firstName: string, lastName: string) {
  return `${firstName.trim().slice(0, 1).toUpperCase()}${lastName.trim().slice(0, 1).toUpperCase()}`.trim()
}
