import {useNavigate} from 'react-router-dom'
import React, {useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api, useUpdateExternalAccountMutation} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {InquireVerificationCode} from 'src/components/InquireVerificationCode'
import {read2FAError} from 'src/helpers/error-formatting'

const SelectPrimaryBankPage = () => {
  const navigate = useNavigate()
  const {
    data: {banks, cards},
  } = api.externalAccounts.list.useSuspenseQuery()
  const updateExternalAccountMutation = useUpdateExternalAccountMutation()
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
    null,
  )
  const alertRef = useRef<WebUI.DialogInstance>(null)
  const growlActions = WebUI.useGrowlActions()

  return (
    <WebUI.Alert
      ref={alertRef}
      aria-label="Select primary withdrawal account"
      initialVisible
      onDidHide={() => navigate('..')}
    >
      <WebUI.AlertHeader>Primary Withdrawal Account</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          <WebUI.VStack className="gap-3">
            <WebUI.VStack className="gap-4">
              <span>
                This is your primary account. You’ll need to make another
                account your primary withdrawal method in order to delete this
                one.
              </span>
              {banks.length + cards.length > 1 && (
                <SelectPrimaryAccount
                  banks={banks.filter((bank) => !bank.isDefault)}
                  cards={cards.filter((card) => !card.isDefault)}
                  selectAccountId={(newAccountId) =>
                    setSelectedAccountId(newAccountId)
                  }
                  selectedAccountId={selectedAccountId}
                />
              )}
            </WebUI.VStack>
            <LinkButton
              className="self-start"
              size="compact"
              variant="secondary"
              to="../bank"
            >
              Add a Checking Account
            </LinkButton>
          </WebUI.VStack>
        }
        actions={
          <InquireVerificationCode>
            {(verificationHelpers) => (
              <WebUI.AlertActionButton
                disabled={!selectedAccountId}
                execute={async () => {
                  if (!selectedAccountId) {
                    return
                  }

                  try {
                    const {verificationCode} =
                      await verificationHelpers.verifyPhone()
                    const acc = await updateExternalAccountMutation.mutateAsync(
                      {
                        pathParams: {
                          accountId: selectedAccountId,
                        },
                        body: {
                          default: true,
                          ...(verificationCode && {
                            security: {token: verificationCode},
                          }),
                        },
                      },
                    )
                    growlActions.show('success', {
                      title: 'Primary account changed',
                      body: `${acc.nickname} was selected as primary bank.`,
                    })
                    alertRef.current?.hide()
                  } catch (err: any) {
                    growlActions.clear()
                    growlActions.show('error', {
                      title: 'Error',
                      body:
                        read2FAError(err) ||
                        err.message ||
                        'Something went wrong while changing primary bank',
                    })
                  }
                }}
              >
                Save
              </WebUI.AlertActionButton>
            )}
          </InquireVerificationCode>
        }
      />
    </WebUI.Alert>
  )
}

// MARK: – SelectPrimaryAccount

interface SelectPrimaryAccountProps
  extends React.ComponentPropsWithoutRef<'fieldset'> {
  banks: Api.BankAccount[]
  cards: Api.CreditCard[]
  selectAccountId: (newAccountId: string) => void
  selectedAccountId: string | null
}

const SelectPrimaryAccount = ({
  banks,
  cards,
  selectAccountId,
  selectedAccountId,
  ...restProps
}: SelectPrimaryAccountProps) => {
  const renderWithdrawalAccount = (
    account: Api.BankAccount | Api.CreditCard,
    type: 'bank' | 'card',
  ) => (
    <WebUI.Radio key={account.id} value={account.id}>
      <span>{`${account.nickname} ${type === 'card' ? '(Debit)' : ''}`}</span>
      <span>****{account.last4}</span>
    </WebUI.Radio>
  )

  return (
    <WebUI.FormField label="Select a Primary Account" {...restProps}>
      <WebUI.RadioGroup
        className="max-h-[200px] overflow-y-auto"
        aria-label="Primary account select"
        size="compact"
        state={selectedAccountId ?? undefined}
        onChange={(event) => selectAccountId(event.target.value)}
      >
        {banks.map((bank) => renderWithdrawalAccount(bank, 'bank'))}
        {cards.map((card) => renderWithdrawalAccount(card, 'card'))}
      </WebUI.RadioGroup>
    </WebUI.FormField>
  )
}

export default SelectPrimaryBankPage
