import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {api} from '@cheddarup/api-client'
import {useIsAuthed} from 'src/hooks/useAuthToken'

import config from '../config'
import {Link} from './Link'
import {AbsoluteLinkButton, LinkButton} from './LinkButton'
import {UserMenu} from './UserMenu'
import {Logo} from './Logo'
import MarketplaceHeading from './MarketplaceHeading'
import {useManagerRoleId} from './ManageRoleProvider'
import {SideNavNavLinkButton} from './SideNavNavLinkButton'
import {MyAccountSidebar} from './CollectionsMobileLayout/MyAccountSidebar'
import {TemplatesSuggestionsModal} from 'src/components/TemplatesSuggestionsModal'

export const AppHeader = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const isLoggedIn = useIsAuthed()
  const subscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    enabled: isLoggedIn,
    select: (session) => session.capabilities.subscribed_to_team,
  })
  const [managerRoleId] = useManagerRoleId()

  return (
    <WebUI.HStack
      className={WebUI.cn(
        'min-h-17 items-center justify-between gap-2 border-b bg-trueWhite px-3',
        className,
      )}
      {...restProps}
    >
      <WebUI.HStack className="gap-2">
        <WebUI.AnchorButton href={config.links.marketingPage}>
          <Logo />
        </WebUI.AnchorButton>

        <AppHeaderUserLinks />
      </WebUI.HStack>

      {isLoggedIn ? (
        <WebUI.HStack className="gap-2">
          {managerRoleId == null && !subscribedToTeamQuery.data && (
            <AbsoluteLinkButton
              className="h-auto"
              variant="secondary"
              to="i/plans"
            >
              Upgrade
            </AbsoluteLinkButton>
          )}
          <UserMenu />
        </WebUI.HStack>
      ) : (
        <WebUI.HStack className="gap-2">
          <LinkButton variant="default" preserveSearch to="/signup">
            Sign up FREE
          </LinkButton>
          <Link
            className="w-[94px] text-center text-ds-sm"
            preserveSearch
            to="/login"
          >
            Log In
          </Link>
        </WebUI.HStack>
      )}
    </WebUI.HStack>
  )
}

// MARK: NewAppHeader

export const NewAppHeader: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...restProps
}) => {
  const subscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_team,
  })
  const [managerRoleId] = useManagerRoleId()

  const isLoggedIn = subscribedToTeamQuery.isSuccess

  return (
    <WebUI.HStack
      className={WebUI.cn(
        'min-h-17 items-center gap-2 border-b bg-trueWhite px-3',
        isLoggedIn ? 'justify-between' : 'justify-evenly',
        className,
      )}
      {...restProps}
    >
      <WebUI.HStack className="gap-2">
        <WebUI.AnchorButton href={config.links.marketingPage}>
          <Logo />
        </WebUI.AnchorButton>

        <NewAppHeaderUserLinks />
      </WebUI.HStack>

      {isLoggedIn ? (
        <WebUI.HStack className="gap-4">
          <LinkButton variant="secondary" to="/managers">
            Add Managers
          </LinkButton>
          {managerRoleId == null && !subscribedToTeamQuery.data && (
            <AbsoluteLinkButton variant="default" to="i/plans">
              Upgrade
            </AbsoluteLinkButton>
          )}
          <UserMenu forNewNav />
        </WebUI.HStack>
      ) : (
        <WebUI.HStack className="gap-2">
          <Link
            className={
              'w-[94px] text-center [&_>_.Anchor-content]:font-semibold [&_>_.Anchor-content]:text-contentPrimary'
            }
            preserveSearch
            to="/login"
          >
            Sign In
          </Link>
          <WebUI.RoundedButton
            as={LinkButton}
            variant="default"
            preserveSearch
            to="/signup"
          >
            Get Started
          </WebUI.RoundedButton>
        </WebUI.HStack>
      )}
    </WebUI.HStack>
  )
}

// MARK: – AppHeaderMobile

export interface AppHeaderMobileProps extends WebUI.NavigationBarProps {}

export const AppHeaderMobile = ({
  className,
  ...restProps
}: AppHeaderMobileProps) => (
  <WebUI.NavigationBar
    className={WebUI.cn('px-5 py-4', className)}
    center={
      <a href={config.links.marketingPage}>
        <WebUI.LogoIcon className="text-ds-md" />
      </a>
    }
    {...restProps}
  />
)

// MARK: – NewAppHeaderMobile

export interface NewAppHeaderMobileProps extends WebUI.NavigationBarProps {}

export const NewAppHeaderMobile: React.FC<NewAppHeaderMobileProps> = (
  props,
) => {
  const isAuthed = useIsAuthed()
  return (
    <AppHeaderMobile
      left={<NewAppHeaderMobileSidebar />}
      right={isAuthed && <MyAccountSidebar />}
      {...props}
    />
  )
}

// MARK: – NewAppHeaderMobileSidebar

export const NewAppHeaderMobileSidebar: React.FC<WebUI.SideNavProps> = ({
  className,
  ...restProps
}) => {
  const isLoggedIn = useIsAuthed()
  const sessionQuery = api.auth.session.useQuery(undefined, {
    enabled: isLoggedIn,
    select: (session) => ({
      upvotyToken: session.upvoty?.token,
    }),
  })
  return (
    <WebUI.SideNav
      className={WebUI.cn(
        '[&_.Button-iconBefore]:text-[28px] [&_.Button-iconBefore]:text-teal-50',
        className,
      )}
      disclosure={
        <WebUI.DialogDisclosure as={WebUI.IconButton}>
          <WebUI.PhosphorIcon icon="list" width={30} />
        </WebUI.DialogDisclosure>
      }
      {...restProps}
    >
      {(dialog) => (
        <>
          {getAppHeaderMenuItems(
            isLoggedIn,
            true,
            sessionQuery.data?.upvotyToken,
          ).map((item, idx) => (
            <React.Fragment key={`side_nave_${idx}`}>
              <WebUI.SideNav
                className="[&_>_.SideSheetContentView]:shadow-none"
                backdropClassName="top-16"
                disclosure={
                  <WebUI.DialogDisclosure
                    className={
                      '[&_>_.Button-content]:font-semibold [&_>_.Button-content]:text-contentPrimary [&_>_.Button-content]:text-ds-base'
                    }
                    as={WebUI.SideNavButton}
                    hideDialogOnClick={false}
                  >
                    {item.name}
                  </WebUI.DialogDisclosure>
                }
              >
                <WebUI.SideNavBackButton className="[&_>_.Button-content]:font-semibold [&_>_.Button-content]:text-contentPrimary" />
                <WebUI.Separator />
                {item.subItems.map((subItem, ind) => (
                  <React.Fragment key={`side_nave_btn_${ind}`}>
                    <WebUI.SideNavButton
                      className="[&_>_.Button-content]:font-semibold [&_>_.Button-content]:text-contentPrimary"
                      as={WebUI.AnchorButton}
                      href={subItem.link}
                      target="_blank"
                      onClick={() => dialog.hide()}
                    >
                      {subItem.name}
                    </WebUI.SideNavButton>
                    <WebUI.Separator />
                  </React.Fragment>
                ))}
              </WebUI.SideNav>
              <WebUI.Separator />
            </React.Fragment>
          ))}
          {isLoggedIn ? (
            <WebUI.SideNavButton
              className="m-5 h-[1.875rem] w-[70%] self-start text-center"
              as={LinkButton}
              size="compact"
              variant="secondary"
              to="/logout"
            >
              Log out
            </WebUI.SideNavButton>
          ) : (
            <>
              <SideNavNavLinkButton
                className="[&_>_.Button-content]:font-semibold [&_>_.Button-content]:text-ds-base"
                to="/login"
              >
                Sign In
              </SideNavNavLinkButton>
              <LinkButton
                className="ml-5 self-start"
                as={WebUI.RoundedButton}
                variant="default"
                to="/signup"
              >
                Get Started
              </LinkButton>
            </>
          )}
        </>
      )}
    </WebUI.SideNav>
  )
}

// MARK: – AppHeaderUserLinks

export const AppHeaderUserLinks: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const internalMarketplaceQuery = api.auth.session.useQuery(undefined, {
    select: (session) => ({
      internalMarketplace: session.organization_data?.internalMarketplace,
      upvotyToken: session.upvoty?.token,
    }),
  })
  const isLoggedIn = useIsAuthed()
  return (
    <WebUI.HStack
      className={WebUI.cn(
        'items-center gap-2 font-normal text-ds-sm [&_>_.Link]:px-5 [&_>_.MenuButton]:px-5',
        className,
      )}
      {...restProps}
    >
      {isLoggedIn ? (
        <>
          <Link to="/collections">Collections</Link>
          <TemplatesSuggestionsModal
            initialVisible={false}
            disclosure={
              <WebUI.DialogDisclosure
                as={WebUI.Anchor}
                className="inline-block h-full w-full cursor-pointer px-5 py-4 text-ds-sm text-gray800"
              >
                Template Library
              </WebUI.DialogDisclosure>
            }
          />
        </>
      ) : (
        <Link to="/signup">Create a Collection</Link>
      )}

      <WebUI.Menu>
        <WebUI.MenuButton variant="text">Help and Inspiration</WebUI.MenuButton>
        <WebUI.MenuList variant="nav">
          <WebUI.MenuItem
            as={WebUI.AnchorButton}
            href="https://www.cheddarup.com/how-does-cheddar-up-work/"
            target="_blank"
            rel="noopener noreferrer"
          >
            How Cheddar Up Works
          </WebUI.MenuItem>
          <WebUI.MenuItem
            as={WebUI.AnchorButton}
            href="https://www.cheddarup.com/demo"
            target="_blank"
            rel="noopener noreferrer"
          >
            Watch a Demo
          </WebUI.MenuItem>
          <WebUI.MenuItem
            as={WebUI.AnchorButton}
            href="https://www.cheddarup.com/learning-sessions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learning Sessions
          </WebUI.MenuItem>
          <WebUI.MenuItem
            as={WebUI.AnchorButton}
            href="https://support.cheddarup.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Knowledge Center
          </WebUI.MenuItem>
          <WebUI.MenuItem
            as={WebUI.AnchorButton}
            href={
              internalMarketplaceQuery.data?.upvotyToken
                ? `https://feedback.cheddarup.com?custom_sso_token=${internalMarketplaceQuery.data.upvotyToken}`
                : 'https://feedback.cheddarup.com'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Give Feedback
          </WebUI.MenuItem>
          <WebUI.MenuItem
            as={WebUI.AnchorButton}
            href="https://www.cheddarup.com/reviews/"
            target="_blank"
            rel="noopener noreferrer"
          >
            User Stories
          </WebUI.MenuItem>
          <WebUI.MenuItem
            as={WebUI.AnchorButton}
            href="https://cheddarup.com/blog"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </WebUI.MenuItem>
        </WebUI.MenuList>
      </WebUI.Menu>
      {internalMarketplaceQuery.data?.internalMarketplace?.enabled && (
        <Link
          to="/marketplace/shop-items"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MarketplaceHeading />
        </Link>
      )}
    </WebUI.HStack>
  )
}

// MARK: – NewAppHeaderUserLinks

const getAppHeaderMenuItems = (
  isLoggedIn = false,
  mobileView = false,
  upvotyToken?: string,
) => [
  {
    name: 'Solutions',
    subItems: [
      {name: 'Feature Overview', link: 'https://www.cheddarup.com/features'},
      {
        name: 'Collect Payments',
        link: 'https://www.cheddarup.com/collect-payments',
      },
      {
        name: 'Collect Information',
        link: 'https://www.cheddarup.com/collect-information',
      },
      {
        name: 'Share & Communicate',
        link: 'https://www.cheddarup.com/share-and-communicate',
      },
      {
        name: 'Track & Manage',
        link: 'https://www.cheddarup.com/track-information',
      },
      {
        name: 'Point of Sale',
        link: 'https://www.cheddarup.com/point-of-sale/',
      },
      {
        name: 'Integrations',
        link: 'https://cheddarup.com/integrations',
      },
    ],
  },
  {
    name: 'Pricing',
    subItems: [
      {name: 'Overview', link: 'https://www.cheddarup.com/pricing/'},
      {name: 'Pro Plan', link: 'https://www.cheddarup.com/pro-plan/'},
      {name: 'Team Plan', link: 'https://www.cheddarup.com/team-plan/'},
    ],
  },
  {
    name: 'About',
    subItems: [
      {name: 'About Us', link: 'https://www.cheddarup.com/about-cheddar-up/'},
      {name: 'Press', link: 'https://www.cheddarup.com/press'},
      {
        name: 'Become an Affiliate',
        link: 'https://www.cheddarup.com/affiliate/',
      },
      {name: 'Partner with Us', link: 'https://www.cheddarup.com/partners/'},
      {name: 'Contact Us', link: 'https://my.cheddarup.com/contact'},
    ],
  },
  {
    name: isLoggedIn ? 'Help and Inspiration' : 'Templates & Resources',
    subItems: isLoggedIn
      ? [
          ...(mobileView
            ? [{name: 'Template Library', link: '/templates'}]
            : []),
          {
            name: 'How Cheddar Up Works',
            link: 'https://www.cheddarup.com/how-does-cheddar-up-work/',
          },
          {name: 'Watch a Demo', link: 'https://www.cheddarup.com/demo'},
          {
            name: 'Learning Sessions',
            link: 'https://www.cheddarup.com/learning-sessions/',
          },
          {
            name: 'Knowledge Center',
            link: 'https://support.cheddarup.com/hc/en-us',
          },
          {
            name: 'Give Feedback',
            link: upvotyToken
              ? `https://feedback.cheddarup.com?custom_sso_token=${upvotyToken}`
              : 'https://feedback.cheddarup.com',
          },
          {
            name: 'Guides',
            link: 'https://support.cheddarup.com/hc/en-us/categories/360002260691-User-Guides',
          },
          {
            name: 'Blog',
            link: 'https://www.cheddarup.com/blog/',
          },
          {
            name: 'Integrations',
            link: 'https://cheddarup.com/integrations',
          },
        ]
      : [
          {name: 'Template Library', link: '/templates'},
          {
            name: 'How Cheddar Up Works',
            link: 'https://www.cheddarup.com/how-does-cheddar-up-work/',
          },
          {name: 'Watch a Demo', link: 'https://www.cheddarup.com/demo'},
          {name: 'User Stories', link: 'https://www.cheddarup.com/reviews/'},
          {
            name: 'Guides',
            link: 'https://support.cheddarup.com/hc/en-us/categories/360002260691-User-Guides',
          },
          {name: 'Blog', link: 'https://www.cheddarup.com/blog/'},
        ],
  },
]

export const NewAppHeaderUserLinks: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const internalMarketplaceQuery = api.auth.session.useQuery(undefined, {
    select: (session) => ({
      internalMarketplace: session.organization_data?.internalMarketplace,
      upvotyToken: session.upvoty?.token,
    }),
  })
  const isLoggedIn = internalMarketplaceQuery.isSuccess

  const renderMenuItems = (from = 0, to = getAppHeaderMenuItems().length) =>
    getAppHeaderMenuItems(
      isLoggedIn,
      false,
      internalMarketplaceQuery.data?.upvotyToken,
    )
      .slice(from, to)
      .map((item, idx) => (
        <WebUI.Menu key={`menu_${idx}`}>
          <WebUI.MenuButton
            variant="text"
            iconAfter={<WebUI.PhosphorIcon icon="caret-down-bold" />}
          >
            {item.name}
          </WebUI.MenuButton>
          <WebUI.MenuList
            data-logged-in={isLoggedIn}
            className={
              '[&:not([data-logged-in=true])_>_.MenuList-inner_.MenuList-body_.MenuItem_.Button-content]:font-semibold [&:not([data-logged-in=true])_>_.MenuList-inner_.MenuList-body_.MenuItem_.Button-content]:text-contentPrimary [&:not([data-logged-in=true])_>_.MenuList-inner_.MenuList-body_.MenuItem_.Button-content]:text-ds-base'
            }
            variant="nav"
          >
            {item.subItems.map((subItem, ind) => (
              <WebUI.MenuItem
                as={WebUI.AnchorButton}
                href={subItem.link}
                target="_blank"
                rel="noopener noreferrer"
                key={`menu_item_${ind}`}
              >
                {subItem.name}
              </WebUI.MenuItem>
            ))}
          </WebUI.MenuList>
        </WebUI.Menu>
      ))

  return (
    <WebUI.HStack
      data-logged-in={isLoggedIn}
      className={WebUI.cn(
        `items-center gap-2 font-normal text-ds-sm [&:not([data-logged-in="true"])]:text-ds-base [&:not([data-logged-in="true"])_>_.MenuButton_.Button-content]:font-semibold [&:not([data-logged-in="true"])_>_.MenuButton_.Button-content]:text-contentPrimary [&_.MenuButton]:px-5 [&_>_.Link]:px-5`,
        className,
      )}
      {...restProps}
    >
      {isLoggedIn && (
        <>
          <Link to="/collections">Collections</Link>
          <TemplatesSuggestionsModal
            initialVisible={false}
            disclosure={
              <WebUI.DialogDisclosure
                as={WebUI.Anchor}
                className="inline-block h-full w-full cursor-pointer px-5 py-4 text-ds-sm text-gray800"
              >
                Template Library
              </WebUI.DialogDisclosure>
            }
          />
        </>
      )}
      {renderMenuItems(isLoggedIn ? getAppHeaderMenuItems().length - 1 : 0)}

      {internalMarketplaceQuery.data?.internalMarketplace?.enabled && (
        <Link
          to="/marketplace/shop-items"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MarketplaceHeading />
        </Link>
      )}
    </WebUI.HStack>
  )
}

export default AppHeader
