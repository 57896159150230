import {useParams} from 'react-router-dom'
import {api} from '@cheddarup/api-client'
import PageTitleHelmet from 'src/components/PageTitleHelmet'
import {CollectionDraftPreviewPage} from 'src/views/collection/manage/CollectionDraftPreviewPage'

export const TemplatePreviewPage = () => {
  const {filterValue, tabSlug} = useParams()
  const templateQuery = api.templates.list.useQuery(undefined, {
    enabled: !!tabSlug,
    select: (templates) => templates.find((t) => t.slug === tabSlug),
  })
  const pageTitle = templateQuery.data
    ? `${templateQuery.data.name}${filterValue ? ` - ${filterValue}` : ''} - Templates | Cheddar Up - Collect Money for Groups Online`
    : undefined

  return (
    <>
      <PageTitleHelmet overrideTitle={pageTitle} />
      <CollectionDraftPreviewPage />
    </>
  )
}

export default TemplatePreviewPage
