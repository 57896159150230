import {Helmet, HelmetProps} from 'react-helmet-async'
import {useLocation, matchPath} from 'react-router-dom'
import {useMemo} from 'react'

type MetaTags = {
  title: string
  seoTitle?: string
  exact?: boolean
}

// Central repository of page titles
const PAGE_TITLES: Record<string, MetaTags> = {
  '/collections': {
    title: 'My Collections | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/share/message-center': {
    title: 'Message Center - Share - Collection Details | Cheddar Up',
    exact: true,
  },
  '/address-book/message-center': {
    title: 'Message Center - Address Book | Cheddar Up',
    exact: true,
  },
  '/address-book': {
    title: 'Address Book | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/manage/payments/:paymentId/refunds': {
    title: 'Refunds - Manage - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/manage/payments/payment/:paymentId/order-summary':
    {
      title: 'Order Summary - Manage - Collection Details | Cheddar Up',
      exact: true,
    },
  '/collection/:collectionId/share': {
    title: 'Share - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/manage/payments': {
    title: 'Payments - Manage - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/manage/items': {
    title: 'Items - Manage - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/manage/forms-signups': {
    title: 'Forms - Manage - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/manage/i/collection/:tabId/summary': {
    title: 'Balance Summary - Manage - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/manage/items/item/:itemId': {
    title:
      'Item Payments and Responses - Manage - Collection Details | Cheddar Up',
  },
  '/collection/:collectionId/manage/items/item/:itemId/report': {
    title: 'Item Report - Manage - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/manage/items/item/:itemId/waitlist': {
    title: 'Item Waitlist - Manage - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/manage/forms-signups/forms/:formId': {
    title: 'Form Responses - Manage - Collection Details | Cheddar Up',
  },
  '/collection/:collectionId/details/forms': {
    title: 'Forms - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/details/forms/add-form': {
    title: 'Add Form - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/details/forms/add-waiver': {
    title: 'Add Waiver - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/details/forms/sign-up': {
    title: 'Add Signup - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/details': {
    title: 'Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/details/information/participation': {
    title:
      'Participation - Additional Information - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/details/information/date-and-location': {
    title:
      'Date and Location - Additional Information - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/details/information/faqs': {
    title: 'FAQs - Additional Information - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/details/information/contact': {
    title: 'Contact - Additional Information - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/details/settings': {
    title: 'Settings - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/details/items': {
    title: 'Items - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/details/items/add-item': {
    title: 'Add Item - Collection Details | Cheddar Up',
    exact: true,
  },
  '/collection/:collectionId/details/items/add-ticket': {
    title: 'Add Ticket - Collection Details | Cheddar Up',
    exact: true,
  },
  '/login': {
    title: 'Login | Cheddar Up - Collect Money for Groups Online',
    seoTitle: 'Login - Collect Money Online for Groups - Cheddar Up',
  },
  '/login/forgot-password': {
    title: 'Forgot Password | Cheddar Up - Collect Money for Groups Online',
    exact: true,
  },
  '/login/forgot-password/reset': {
    title: 'Reset Password | Cheddar Up - Collect Money for Groups Online',
    exact: true,
  },
  '/logout': {
    title: 'Logout | Cheddar Up - Collect Money for Groups Online',
    exact: true,
  },
  '/signup': {
    title: 'Sign Up | Cheddar Up - Collect Money for Groups Online',
    seoTitle: 'Sign Up for Free - Collect Money Online for Groups - Cheddar Up',
  },
  '/signup-as-manager': {
    title:
      'Sign Up as a Manager | Cheddar Up - Collect Money for Groups Online',
    exact: true,
  },
  '/orgs/:org/signup': {
    title:
      'Organization Sign Up | Cheddar Up - Collect Money for Groups Online',
  },
  '/sign-up-via-pos-code': {
    title:
      'Sign Up with a POS Code | Cheddar Up - Collect Money for Groups Online',
    exact: true,
  },
  '/payments': {
    title: 'Payments | Cheddar Up',
    exact: true,
  },
  '/managers': {
    title: 'Managers | Cheddar Up',
    exact: true,
  },
  '/reports': {
    title: 'Reports | Cheddar Up',
    exact: true,
  },
  '/group': {
    title: 'Create a Group Page | Cheddar Up',
    exact: true,
  },
  '/group/add-collections': {
    title: 'Add Collections - Group Page | Cheddar Up',
    exact: true,
  },
  '/group/top-banner': {
    title: 'Top Banner - Group Page | Cheddar Up',
    exact: true,
  },
  '/group/collection-spotlight': {
    title: 'Collection Spotlight - Group Page | Cheddar Up',
    exact: true,
  },
  '/group/about-us': {
    title: 'About Us - Group Page | Cheddar Up',
    exact: true,
  },
  '/group/total-collected': {
    title: 'Total Collected - Group Page | Cheddar Up',
    exact: true,
  },
  '/group/team': {
    title: 'Team - Group Page | Cheddar Up',
    exact: true,
  },
  '/group/sponsors': {
    title: 'Sponsors - Group Page | Cheddar Up',
    exact: true,
  },
  '/templates': {
    title: 'Template Library | Cheddar Up - Collect Money for Groups Online',
  },
  'me/:userId': {
    title: 'Group Page | Cheddar Up',
  },
  'me/:userId/help': {
    title: 'Contact - Group Page | Cheddar Up',
    exact: true,
  },
  'me/:userId/share': {
    title: 'Share - Group Page | Cheddar Up',
    exact: true,
  },
  '/contact': {
    title: 'Contact Us | Cheddar Up',
    exact: true,
  },
  '/:prefix/c/:tabId/items/item/:itemId': {
    title: 'Add Item to Cart | Cheddar Up',
  },
  '/:prefix/c/:tabId/forms/signups/:signupId': {
    title: 'Add Time Slot to Cart | Cheddar Up',
  },
  '/:prefix/c/:tabId/forms/form/:formId': {
    title: 'Add Form to Cart | Cheddar Up',
  },
  '/:prefix/c/:tabId/checkout/thank-you': {
    title: 'Checkout Complete | Cheddar Up',
    exact: true,
  },
  '/:prefix/c/:tabId/checkout': {
    title: 'Checkout | Cheddar Up',
    exact: true,
  },
  '/:prefix/c/:tabId/barriers/timing': {
    title: 'Timing | Cheddar Up',
    exact: true,
  },
  '/:prefix/c/:tabId/barriers/visitor-report': {
    title: 'Visitor Report | Cheddar Up',
    exact: true,
  },
  '/:prefix/c/:tabId/items/payers': {
    title: 'View Payers | Cheddar Up',
    exact: true,
  },
  '/:prefix/c/:tabId/items': {
    title: 'View Items | Cheddar Up',
  },
  '/:prefix/c/:tabId/forms': {
    title: 'View Forms | Cheddar Up',
  },
  'c/:tabId/items/item/:itemId': {
    title: 'Add Item to Cart | Cheddar Up',
  },
  'c/:tabId/forms/signups/:signupId': {
    title: 'Add Time Slot to Cart | Cheddar Up',
  },
  'c/:tabId/forms/form/:formId': {
    title: 'Add Form to Cart | Cheddar Up',
  },
  'c/:tabId/checkout/thank-you': {
    title: 'Checkout Complete | Cheddar Up',
    exact: true,
  },
  'c/:tabId/checkout': {
    title: 'Checkout | Cheddar Up',
    exact: true,
  },
  'c/:tabId/barriers/timing': {
    title: 'Timing | Cheddar Up',
    exact: true,
  },
  'c/:tabId/barriers/visitor-report': {
    title: 'Visitor Report | Cheddar Up',
    exact: true,
  },
  'c/:tabId/items/payers': {
    title: 'View Payers | Cheddar Up',
    exact: true,
  },
  'c/:tabId/items': {
    title: 'View Items | Cheddar Up',
  },
  'c/:tabId/forms': {
    title: 'View Forms | Cheddar Up',
  },
  '/collection/:collectionId/:section/c/:tabId/items': {
    title: 'View Items | Cheddar Up',
  },
  '/:prefix/my-account/account-details': {
    title: 'Account Details - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/my-account/display-name': {
    title: 'Display Name - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/my-account/email-and-password': {
    title: 'Email and Password - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/my-account/notification-settings': {
    title: 'Notification Settings - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/my-account/payment-methods': {
    title: 'Payment Methods - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/my-account/plan-billing': {
    title: 'Plan and Billing - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/my-account/security': {
    title: 'Security - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/my-account/statement-descriptor': {
    title: 'Statement Descriptor - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/my-account/brand-kit-logo': {
    title: 'Logo - Brand Kit - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/my-account/brand-kit-palette': {
    title: 'Palette - Brand Kit - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/my-account/brand-kit-footer': {
    title: 'Footer - Brand Kit - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/my-account/brand-kit-email': {
    title: 'Emails - Brand Kit - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/my-account/zapier-key': {
    title: 'Integrations - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/my-account/alerts': {
    title: 'Alerts - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/my-account/withdrawal-settings': {
    title: 'Withdrawal Settings - My Account | Cheddar Up',
    exact: true,
  },
  '/my-account/account-details': {
    title: 'Account Details - My Account | Cheddar Up',
    exact: true,
  },
  '/my-account/display-name': {
    title: 'Display Name - My Account | Cheddar Up',
    exact: true,
  },
  '/my-account/email-and-password': {
    title: 'Email and Password - My Account | Cheddar Up',
    exact: true,
  },
  '/my-account/notification-settings': {
    title: 'Notification Settings - My Account | Cheddar Up',
    exact: true,
  },
  '/my-account/payment-methods': {
    title: 'Payment Methods - My Account | Cheddar Up',
    exact: true,
  },
  '/my-account/plan-billing': {
    title: 'Plan and Billing - My Account | Cheddar Up',
    exact: true,
  },
  '/my-account/security': {
    title: 'Security - My Account | Cheddar Up',
    exact: true,
  },
  '/my-account/statement-descriptor': {
    title: 'Statement Descriptor - My Account | Cheddar Up',
    exact: true,
  },
  '/my-account/brand-kit-logo': {
    title: 'Logo - Brand Kit - My Account | Cheddar Up',
    exact: true,
  },
  '/my-account/brand-kit-palette': {
    title: 'Palette - Brand Kit - My Account | Cheddar Up',
    exact: true,
  },
  '/my-account/brand-kit-footer': {
    title: 'Footer - Brand Kit - My Account | Cheddar Up',
    exact: true,
  },
  '/my-account/brand-kit-email': {
    title: 'Emails - Brand Kit - My Account | Cheddar Up',
    exact: true,
  },
  '/my-account/zapier-key': {
    title: 'Integrations - My Account | Cheddar Up',
    exact: true,
  },
  '/my-account/alerts': {
    title: 'Alerts - My Account | Cheddar Up',
    exact: true,
  },
  '/:prefix/i/plans': {
    title: 'Plans Overview | Cheddar Up',
  },
  '/:prefix/i/plans/pro': {
    title: 'Pro Plan | Cheddar Up',
  },
  '/:prefix/i/plans/team-upgrade': {
    title: 'Team Plan | Cheddar Up',
  },
  '/:prefix/i/plans/downgrade/pro': {
    title: 'Downgrade Plan To Pro | Cheddar Up',
  },
  '/:prefix/i/plans/downgrade/basic': {
    title: 'Downgrade Plan To Basic | Cheddar Up',
  },
  'i/plans': {
    title: 'Plans Overview | Cheddar Up',
  },
  'i/plans/pro': {
    title: 'Pro Plan | Cheddar Up',
  },
  'i/plans/team-upgrade': {
    title: 'Team Plan | Cheddar Up',
  },
  'i/plans/downgrade/pro': {
    title: 'Downgrade Plan | Cheddar Up',
  },
  '/withdraw': {
    title: 'Withdraw Funds | Cheddar Up',
    exact: true,
  },
  '/my-account/withdrawal-settings': {
    title: 'Withdrawal Settings - My Account | Cheddar Up',
    exact: true,
  },
}

export interface PageTitleHelmetProps extends Omit<HelmetProps, 'title'> {
  overrideTitle?: string
  overrideSeoTitle?: string
  children?: React.ReactNode
}

const matchRoute = (
  pattern: string,
  pathname: string,
  exact?: boolean,
): boolean => {
  // Special case: if the pattern starts with /:prefix/, it means we want to match anything before the rest of the pattern
  if (pattern.startsWith('/:prefix/')) {
    const suffixPattern = pattern.slice('/:prefix'.length)
    // Extract the actual path after any prefix
    const pathParts = pathname.split('/')
    const suffixStart = pathParts.findIndex(
      (part, index) => index > 0 && part === suffixPattern.split('/')[1],
    )
    if (suffixStart === -1) return false

    const actualPath = `/${pathParts.slice(suffixStart).join('/')}`

    // Use matchPath for the suffix comparison since it might contain dynamic segments
    return !!matchPath(
      {
        path: suffixPattern,
        end: exact,
      },
      actualPath,
    )
  }

  // Try matchPath as a fallback
  return !!matchPath(
    {
      path: exact ? pattern : `${pattern}/*`,
      end: exact,
    },
    pathname,
  )
}

export const PageTitleHelmet = ({
  overrideTitle,
  overrideSeoTitle,
  children,
  ...props
}: PageTitleHelmetProps) => {
  const location = useLocation()

  const {title, seoTitle} = useMemo(() => {
    if (overrideTitle) {
      return {
        title: overrideTitle,
        seoTitle: overrideSeoTitle || overrideTitle,
      }
    }

    // Find matching title mapping
    const match = Object.entries(PAGE_TITLES).find(([path, meta]) =>
      matchRoute(path, location.pathname, meta.exact),
    )

    const defaultTitle = 'Cheddar Up - Collect Money for Groups Online'

    // Check if this is a preview route (has /c/ with a prefix)
    const isPreviewRoute =
      location.pathname.includes('/c/') && !location.pathname.startsWith('/c/')
    const baseTitle = match?.[1].title || defaultTitle
    const finalTitle = isPreviewRoute
      ? baseTitle.replace(' | Cheddar Up', ' | Cheddar Up (Preview)')
      : baseTitle

    return {
      title: finalTitle,
      seoTitle: match?.[1].seoTitle || baseTitle,
    }
  }, [location.pathname, overrideTitle, overrideSeoTitle])

  return (
    <Helmet {...props}>
      <title>{title}</title>
      <meta name="title" content={seoTitle} />
      <meta property="og:title" content={seoTitle} />
      <meta name="twitter:title" content={seoTitle} />
      <meta
        name="description"
        content="Collect money online in minutes. Cheddar Up helps you collect payments and track information from a group or community. Anyone can pay without having an app or account. You can use Cheddar Up to collect both online and in-person payments for almost anything - from membership dues to group gifts to events to sales."
      />
      {children}
    </Helmet>
  )
}

export default PageTitleHelmet
