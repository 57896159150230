import * as Ariakit from '@ariakit/react'
import React from 'react'

import {cn} from '../../utils'
import {getAntialiasClassName} from './Text'

export interface NextHeadingProps extends Ariakit.RoleProps<'h1'> {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7' | 'h8'
}

export const NextHeading = React.forwardRef<
  HTMLHeadingElement,
  NextHeadingProps
>(({as = 'h1', className, ...restProps}, forwardedRef) => {
  const tag = as === 'h7' || as === 'h8' ? 'h6' : as
  const Comp = Ariakit.Role[tag]

  const resolvedClassName = cn(
    'font-accentAlt font-normal',
    {
      h8: 'text-h-8',
      h7: 'text-h-7',
      h6: 'text-h-6',
      h5: 'text-h-5',
      h4: 'text-h-4',
      h3: 'text-h-3',
      h2: 'text-h-2',
      h1: 'text-h-1',
    }[as],
  )

  return (
    <Comp
      ref={forwardedRef}
      className={cn(
        resolvedClassName,
        getAntialiasClassName(resolvedClassName, className),
        className,
      )}
      {...restProps}
    />
  )
})
