import React, {useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'

import {EntityLabel} from './EntityTypeForm'

export interface DiscoveryFormValues {
  referralSource: string
}

interface DiscoveryFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  initialValues: DiscoveryFormValues
  onSubmit: (values: DiscoveryFormValues) => void
}

const OTHER_SOURCES = ['Event', 'Other']

const REFERRAL_SOURCES = [
  "I've paid through Cheddar Up",
  'Used Cheddar Up as an organizer',
  'My group uses Cheddar Up',
  'Recommended by friend/group',
  'Internet Search',
  'Social Media',
  ...OTHER_SOURCES,
]

const DiscoveryForm = ({
  initialValues,
  onSubmit,
  ...restProps
}: DiscoveryFormProps) => {
  const [referralSource, setReferralSource] = useState<
    DiscoveryFormValues['referralSource']
  >(initialValues.referralSource)
  const [otherSource, setOtherSource] = useState('')

  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <form
      className="flex flex-col gap-7"
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit({
          referralSource: OTHER_SOURCES.includes(referralSource)
            ? otherSource || referralSource
            : referralSource,
        })
      }}
      {...restProps}
    >
      <NextUI.Text className="font-black text-ds-lg">
        How did you hear about Cheddar Up?
      </NextUI.Text>
      <WebUI.ToggleGroup
        className="flex-wrap gap-6"
        value={referralSource}
        onValueChange={(value) => {
          setReferralSource(value || '')
          if (value && OTHER_SOURCES.includes(value)) {
            setOtherSource('')
            setTimeout(() => {
              inputRef.current?.focus()
            }, 0)
          }
        }}
      >
        {REFERRAL_SOURCES.map((source, idx) =>
          OTHER_SOURCES.includes(referralSource) &&
          referralSource === source ? (
            <NextUI.Input
              ref={inputRef}
              placeholder={
                source === 'Other'
                  ? 'How did you hear about us?'
                  : 'We’d love to know which event'
              }
              className="h-auto w-full px-4 sm:w-[46%]"
              roundness="capsule"
              key={idx}
              value={otherSource}
              onChange={(e) => setOtherSource(e.target.value)}
            />
          ) : (
            <WebUI.ToggleGroupItem
              as={EntityLabel}
              className="w-full sm:w-[46%]"
              label={source}
              value={source}
              key={idx}
            />
          ),
        )}
      </WebUI.ToggleGroup>
      <WebUI.RoundedButton
        className="w-[180px]"
        type="submit"
        disabled={!referralSource}
      >
        Continue
      </WebUI.RoundedButton>
    </form>
  )
}

export default DiscoveryForm
