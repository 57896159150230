import React, {useRef} from 'react'
import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import {useFormik} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'
import queryString from 'query-string'
import {useConvertTemplateToTabMutation} from '@cheddarup/api-client'
import {useSignup} from 'src/hooks/useAuth'

import config from '../../../config'
import type {EntityTypeFormValues} from './EntityTypeForm'
import type {CustomerCategoryFormValues} from './CustomerCategoryForm'
import {DiscoveryFormValues} from './DiscoveryForm'

interface AccountDetailsFormValues {
  first_name: string
  last_name: string
  display_name: string
  marketingOptInFlag: boolean
  email: string
  confirm_email: string
  password: string
}

interface AccountDetailsFormProps
  extends React.ComponentPropsWithoutRef<'form'> {
  values: EntityTypeFormValues &
    CustomerCategoryFormValues &
    DiscoveryFormValues
}

const AccountDetailsForm = ({
  values: valuesProp,
  className,
  ...restProps
}: AccountDetailsFormProps) => {
  const convertTemplateToTabMutation = useConvertTemplateToTabMutation()
  const recaptchaRef = useRef<WebUI.RecaptchaInstance>(null)
  const [email] = useQueryParam('email', withDefault(StringParam, ''))
  const [, setConvertedTabId] = useQueryParam('convertedTabId', NumberParam)
  const [, setPreventAuthRedirect] = useQueryParam(
    'preventAuthRedirect',
    BooleanParam,
  )
  const [signup] = useSignup({recaptchaRef})

  const growlActions = WebUI.useGrowlActions()

  const queryParams = queryString.parse(window.location.search)

  const formik = useFormik<AccountDetailsFormValues>({
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      display_name:
        valuesProp.entityType === 'individual'
          ? Yup.string()
          : Yup.string().required('Required'),
      email: Yup.string().email('Email is Invalid').required('Required'),
      confirm_email: Yup.string()
        .email('Email is Invalid')
        .required('Required')
        .test('email-match', 'Email must match', function (value) {
          return value.toLowerCase() === this.parent.email.toLowerCase()
        }),
      password: Yup.string()
        .required('Required')
        .min(6, 'Password must contain at least 6 characters')
        .matches(/\d/, 'Password must contain at least 1 number'),
    }),
    initialValues: {
      first_name:
        typeof queryParams.first_name === 'string'
          ? queryParams.first_name
          : typeof queryParams.name === 'string'
            ? (queryParams.name.split(' ')[0]?.trim() ?? '')
            : '',
      last_name:
        typeof queryParams.last_name === 'string'
          ? queryParams.last_name
          : typeof queryParams.name === 'string'
            ? (queryParams.name.split(' ')[1]?.trim() ?? '')
            : '',
      display_name:
        typeof queryParams.name === 'string' ? queryParams.name : '',
      email,
      confirm_email: '',
      password: '',
      marketingOptInFlag: true,
    },
    onSubmit: async (values) => {
      if (typeof queryParams.templateId === 'string') {
        setPreventAuthRedirect(true)
      }

      try {
        const res = await signup({
          first_name: values.first_name,
          last_name: values.last_name,
          display_name:
            valuesProp.entityType === 'individual' && !values.display_name
              ? `${values.first_name} ${values.last_name}`
              : values.display_name,
          email: values.email,
          confirm_email: values.confirm_email,
          password: values.password,
          entity_type: valuesProp.entityType,
          customer_category: valuesProp.customerCategory,
          partner: queryParams.org,
          invite_code: queryParams.invite_code,
          profile: {
            referrer: {
              heardFrom: valuesProp.referralSource,
              url: queryParams,
            },
            uiClientFlags: {
              disabledEmailNotifications: {
                marketingOptIn: !values.marketingOptInFlag,
              },
            },
          },
        })

        if (!res) {
          return
        }
      } catch (err: any) {
        growlActions.show('error', {
          title: 'Error',
          body:
            Object.values(err.response?.data?.errors || {})[0] ||
            err.response?.message ||
            'Something went wrong',
        })
      }

      if (typeof queryParams.templateId === 'string') {
        try {
          const convertedTab = await convertTemplateToTabMutation.mutateAsync({
            pathParams: {
              templateId: queryParams.templateId,
            },
          })
          setConvertedTabId(convertedTab.id)
          setPreventAuthRedirect(false)
        } catch (err: any) {
          const apiError = err?.response?.data?.errors?.[0]
          growlActions.show('error', {
            title: Util.startCase(apiError?.error || 'Error'),
            body: apiError?.details || 'Something went wrong',
          })
        }
      }
    },
  })

  return (
    <form
      className={WebUI.cn('flex flex-col gap-7', className)}
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...restProps}
    >
      <div className="flex flex-col gap-4">
        <WebUI.Text className="font-black text-ds-lg">
          Your account details
        </WebUI.Text>
        <WebUI.Text className="font-light">
          For verification purposes, please use your legal first and last name
          (no nicknames).
        </WebUI.Text>
        <WebUI.FormFieldGroup>
          <WebUI.FloatingLabelFormTextField
            label="Legal first name"
            error={formik.errors.first_name}
          >
            <WebUI.Input
              name="first_name"
              autoComplete="given-name"
              size="xl"
              roundness="capsule"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FloatingLabelFormTextField>
          <WebUI.FloatingLabelFormTextField
            label="Legal last name"
            error={formik.errors.last_name}
          >
            <WebUI.Input
              name="last_name"
              autoComplete="family-name"
              size="xl"
              roundness="capsule"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FloatingLabelFormTextField>
        </WebUI.FormFieldGroup>

        <WebUI.FloatingLabelFormTextField
          label={
            valuesProp.entityType === 'individual'
              ? 'Name of your organization (optional)'
              : 'Legal name of your organization'
          }
          error={formik.errors.display_name}
          caption="This is the public-facing name that will appear on your collection pages."
        >
          <WebUI.Input
            name="display_name"
            autoComplete="name"
            size="xl"
            roundness="capsule"
            placeholder={
              valuesProp.entityType === 'individual'
                ? 'Optional'
                : 'Legal name of your organization'
            }
            value={formik.values.display_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FloatingLabelFormTextField>
        <WebUI.FloatingLabelFormTextField
          label="Email Address"
          error={formik.errors.email}
        >
          <WebUI.Input
            name="email"
            type="email"
            autoComplete="email"
            size="xl"
            roundness="capsule"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FloatingLabelFormTextField>
        <WebUI.FloatingLabelFormTextField
          label="Confirm Email Address"
          error={formik.errors.confirm_email}
        >
          <WebUI.Input
            name="confirm_email"
            type="email"
            autoComplete="off"
            size="xl"
            roundness="capsule"
            value={formik.values.confirm_email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FloatingLabelFormTextField>
        <WebUI.PasswordFloatingLabelFormTextFieldInput
          name="password"
          caption="Passwords must contain at least 6 characters and 1 number."
          error={formik.errors.password}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <WebUI.Text className="font-light text-ds-sm [&_.Anchor-content]:font-light">
          By continuing, I agree to the Cheddar Up's{' '}
          <WebUI.Anchor
            target="_blank"
            rel="noopener noreferrer"
            href={config.links.terms}
          >
            Terms of Use
          </WebUI.Anchor>{' '}
          and{' '}
          <WebUI.Anchor
            href={config.links.privacyPolicy}
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </WebUI.Anchor>
        </WebUI.Text>
        <WebUI.Checkbox
          className="flex"
          name="marketingOptInFlag"
          checked={formik.values.marketingOptInFlag}
          onChange={(event) =>
            formik.setFieldValue('marketingOptInFlag', event.target.checked)
          }
        >
          <div>
            <div className="font-light text-ds-sm">
              Keep me updated with exclusive tips, clever ideas, and inspiring
              stories to make collecting money a breeze. (No spam, we promise!)
            </div>
          </div>
        </WebUI.Checkbox>
      </div>
      <WebUI.RoundedButton
        className="w-[200px]"
        type="submit"
        variant="primary"
        loading={formik.isSubmitting}
      >
        Start Collecting
      </WebUI.RoundedButton>
      {config.isProd && <WebUI.Recaptcha ref={recaptchaRef} />}
    </form>
  )
}

export default AccountDetailsForm
