import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {BooleanParam, NumberParam, useQueryParam} from 'use-query-params'
import {useFormik} from '@cheddarup/react-util'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import * as Util from '@cheddarup/util'
import {
  useAddToWaitlistMutation,
  useUpdateCartItemMutation,
} from '@cheddarup/api-client'
import {
  FieldSetList,
  FieldSetListInstance,
  FieldValue,
  makeFieldInitialValues,
  makeFieldsYupSchema,
} from 'src/components/FieldSetList'
import {
  ItemAmountDisplay,
  getItemAmountType,
  getItemBaseAmount,
} from 'src/components/ItemAmountDisplay'
import {RecurringOptionsAnnotation} from 'src/components'
import RecurringPaymentFormatter from 'src/helpers/RecurringPaymentFormatter'
import {readApiError} from 'src/helpers/error-formatting'

import {ItemViewImageGallery, ListingSelect} from './components'
import useCart, {
  useEnhancedCreateCartItemMutation,
  useEnhancedResolveCartFieldValues,
} from '../hooks/useCart'
import usePublicCollection from '../hooks/usePublicCollection'
import {usePayerUIState} from '../PayerUIStateProvider'
import {useIsItemViewSoldOut} from '../utils/cart-item-utils'
import {RecurringPaymentIndicator} from '../components/RecurringPaymentIndicator'
import {PayerButton} from '../components/PayerStyled'
import {
  formatQuantityDiscount,
  getItemPriceWithFee,
  getPayerBrandKitColors,
} from '@cheddarup/core'
import {
  NextPopover,
  NextPopoverContent,
  NextPopoverDisclosure,
  NextPopoverInstance,
  NextPopoverProps,
} from '@cheddarup/web-ui/next'

const ItemViewPage: React.FC = () => {
  const urlParams = useParams()
  return <ItemViewPageImpl key={urlParams.item} />
}

const ItemViewPageImpl: React.FC = () => {
  const [ciId] = useQueryParam('ciId', NumberParam)
  const [addPayment] = useQueryParam('add-payment', BooleanParam)
  const [preview] = useQueryParam('preview', BooleanParam)
  const navigate = useNavigate()
  const location = useLocation()
  const urlParams = useParams()
  const payerUIState = usePayerUIState()
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const [, createCartItemAsync] = useEnhancedCreateCartItemMutation()
  const resolveCartFieldValues = useEnhancedResolveCartFieldValues()
  const updateCartItemMutation = useUpdateCartItemMutation()
  const addToWaitlistMutation = useAddToWaitlistMutation()
  const growlActions = WebUI.useGrowlActions()
  const modalRef = useRef<WebUI.DialogInstance>(null)
  const joinWaitlistConfirmationModalRef = useRef<WebUI.DialogInstance>(null)
  const amountFormFieldRef = useRef<HTMLFieldSetElement>(null)
  const suggestedAmountLabeldRef = useRef<HTMLLabelElement>(null)
  const quantityFormFieldRef = useRef<HTMLFieldSetElement>(null)
  const listingSelectRef = useRef<HTMLDivElement>(null)
  const fieldSetListRefMap = useRef<
    Record<number, FieldSetListInstance | null>
  >({})
  const [fieldSetListKey, setFieldSetListKey] = useState(Util.makeShortId())
  const [customAmountInputMode, setCustomAmountInputMode] = useState(false)

  // biome-ignore lint/style/noNonNullAssertion:
  const item = publicCollection.items.find(
    (i) => i.id === Number(urlParams.item),
  )!
  const [hasImages, setHasImages] = useState(item.images.length > 0)
  const cartItem = cart?.items.find((i) => i.id === ciId)

  const isTicket = item?.options.itemSubType === 'ticket'
  const isNewTicket = isTicket && !cartItem
  const minimumAmount = item.options.donation?.suggestedAmounts?.minimumAmount
  const initialFields = makeFieldInitialValues(
    item.fields,
    cartItem?.cart_field_views,
  )

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: () => {
      if (waitlistEnabled) {
        return Yup.object().shape({
          first_name: Yup.string().required(),
          last_name: Yup.string().required(),
          email: Yup.string().email('Invalid email').required(),
          confirm_email: Yup.string().oneOf(
            [Yup.ref('email')],
            'Email does not match',
          ),
          quantity_desired: Yup.number().required(),
        })
      }

      const fieldsSchema = Yup.object().shape(makeFieldsYupSchema(item.fields))

      let schema = Yup.object({
        fields: isNewTicket ? Yup.array().of(fieldsSchema) : fieldsSchema,
      })

      if (item.options.variants?.enabled) {
        schema = schema.shape({listingUuid: Yup.string().required()})
      }
      if (!item.options.variants?.enabled && item.amount_type === 'open') {
        schema = schema.shape({
          amount: Yup.number()
            .required('Required')
            .min(minimumAmount ?? 1, 'Amount must be at least ${min}')
            .max(999999, 'Price must be less than 1,000,000.00'),
        })
      }

      const totalQuantity =
        (cart?.items
          .filter((ci) => ci.tab_item.id === item.id)
          .reduce((acc, ci) => acc + ci.quantity, 0) ?? 0) -
        (cartItem?.quantity ?? 0)

      const groupedQuantities = item?.inventory_items
        .filter((ii) =>
          listing
            ? ii.variant_uuid === listing.uuid
            : ii.variant_uuid !== 'NONE',
        )
        .flatMap((ii) => ii.inventory_groups.map((ig) => ig.available_quantity))

      const perPersonMaxQuantity = item.options.perPersonMaxQuantity?.enabled
        ? item.options.perPersonMaxQuantity.value - totalQuantity
        : Number.POSITIVE_INFINITY
      const availableQuantity = item.options.variants?.enabled
        ? Math.min(
            listing?.available_quantity ?? Number.POSITIVE_INFINITY,
            ...groupedQuantities,
          )
        : (item.available_quantity ?? Number.POSITIVE_INFINITY)

      schema = schema.shape({
        quantity: Yup.number()
          .typeError('Required')
          .required('Required')
          .min(1, 'Required')
          .max(
            Math.min(availableQuantity, perPersonMaxQuantity),
            perPersonMaxQuantity < availableQuantity
              ? 'You can only purchase ${max} of this item'
              : 'Quantity can not exceed ${max}',
          ),
      })

      return schema
    },
    initialValues: {
      quantity: cartItem?.quantity ?? 1,
      amount: cartItem?.amount ?? undefined,
      listingUuid: cartItem?.detail?.variant?.uuid,
      fields: isNewTicket
        ? [Object.fromEntries(item.fields.map((f) => [f.id, '']))]
        : initialFields,
      first_name: '',
      last_name: '',
      email: '',
      confirm_email: '',
      quantity_desired: 1,
    },
    onSubmit: async (values) => {
      if (addPayment && item.options.recurring?.enabled) {
        growlActions.show('error', {
          title: 'Error',
          body: 'You cannot record a recurring payment.',
        })
        return
      }
      if (waitlistEnabled) {
        try {
          const payload = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            quantity_desired: values.quantity_desired,
            ...(values.listingUuid && {
              variant_uuid: values.listingUuid,
            }),
          }
          await addToWaitlistMutation.mutate({
            pathParams: {
              tabId: publicCollection.slug,
              itemId: item.id,
            },
            body: payload,
          })
          joinWaitlistConfirmationModalRef.current?.show()
        } catch (err) {
          const errorMessage = readApiError(err)
          growlActions.show('error', {
            title: 'Error',
            body: errorMessage,
          })
        }
        return
      }

      try {
        if (isNewTicket) {
          const cartFieldValuesList = Array.from({length: values.quantity}).map(
            (_, index) =>
              resolveCartFieldValues({
                fields: item.fields,
                values: values.fields[index] as Record<number, FieldValue>,
              }),
          )
          const cartFieldValuesResults = await Promise.all(cartFieldValuesList)

          const createCartItemMutationList = cartFieldValuesResults.map(
            (cartFieldValues) =>
              createCartItemAsync({
                body: {
                  item_id: item.id,
                  variant_id: listing?.uuid,
                  quantity: 1,
                  amount: values.amount,
                  cart_field_values: cartFieldValues,
                },
              }),
          )
          await Promise.all(createCartItemMutationList)
        } else {
          const cartFieldValues = await resolveCartFieldValues({
            fields: item.fields,
            values: values.fields as Record<number, FieldValue>,
          })

          const payload = {
            item_id: item.id,
            variant_id: listing?.uuid,
            quantity: values.quantity,
            amount: values.amount,
            cart_field_values: cartFieldValues,
          }

          if (cartItem) {
            if (!cart) {
              return
            }

            await updateCartItemMutation.mutateAsync({
              pathParams: {
                tabId: publicCollection.slug,
                cartUuid: cart.uuid,
                itemId: cartItem.id,
              },
              body: payload,
            })
          } else {
            await createCartItemAsync({body: payload})
          }
        }

        payerUIState.setCartVisible(true)
        modalRef.current?.hide()
      } catch (err) {
        const errorMessage = readApiError(err, {
          exceeded_available_quantity: (details: any) =>
            details.available > 0
              ? `there ${details.available === 1 ? 'is' : 'are'} only ${
                  details.available
                } available for sale`
              : `Sorry, you requested ${details.requested} of ${details.tab_item_name}, but it is sold out.`,
          cannot_subscribe_to_own_collection:
            'You cannot subscribe to your own recurring payment items.',
          amount_required: 'Amount is required',
        })

        growlActions.show('error', {
          title: 'Error',
          body: errorMessage,
        })
      }
    },
  })

  const isSoldOut = useIsItemViewSoldOut({
    itemId: Number(urlParams.item),
    listingUuid: formik.values.listingUuid,
  })
  const listings = useMemo(
    () =>
      (item.options.variants?.enabled && item.options.variants?.listings) || [],
    [item.options.variants?.enabled, item.options.variants?.listings],
  )
  const listing = useMemo(
    () => listings.find(({uuid}) => uuid === formik.values.listingUuid) ?? null,
    [listings, formik.values.listingUuid],
  )
  const suggestedDonationAmounts = useMemo(
    () => item.options.donation?.suggestedAmounts?.values ?? [],
    [item.options.donation?.suggestedAmounts?.values],
  )
  const donationAmountSuggestionsEnabled =
    item.options.donation?.suggestedAmounts?.enabled &&
    suggestedDonationAmounts.length > 0

  const waitlistEnabled =
    isSoldOut && item.options.waitlist && item.options.waitlist.enabled
  const brandKitColors = getPayerBrandKitColors(
    publicCollection.organizer.branding?.color_palette.payerPage,
  )
  const feeTransparencyEnabled = publicCollection.fee_transparency
  const itemBasePrice =
    getItemBaseAmount(item, {listingUuid: listing?.uuid}) ??
    formik.values.amount
  const basePriceWithFees = getItemPriceWithFee({
    itemBasePrice,
    collection: publicCollection,
    cart,
  })
  const itemTotalPriceWithFees = getItemPriceWithFee({
    itemBasePrice:
      (itemBasePrice ?? formik.values.amount ?? 0) * formik.values.quantity,
    collection: publicCollection,
    cart,
  })
  const isAmountTypeOpen = getItemAmountType(item) === 'open'

  useEffect(() => {
    if (
      cartItem?.amount &&
      !suggestedDonationAmounts.includes(cartItem.amount)
    ) {
      setCustomAmountInputMode(true)
    }
  }, [cartItem?.amount, suggestedDonationAmounts])

  const baseAmount = getItemBaseAmount(item, {listingUuid: listing?.uuid})
  const shouldShowFees = feeTransparencyEnabled && baseAmount !== 0

  return (
    <WebUI.Modal
      data-has-images={hasImages}
      aria-label="Item"
      ref={modalRef}
      className={
        '[&_>_.ModalContentView]:overflow-hidden data-[has-images=true]:[&_>_.ModalContentView]:max-w-screen-lg sm:[&_>_.ModalContentView]:max-w-[512px] sm:[&_>_.ModalContentView]:rounded-extended md:[&_>_.ModalContentView]:h-[672px]'
      }
      preventBodyScroll
      onDidHide={() =>
        navigate({
          pathname: '..',
          search: Util.excludeSearchParams(location.search, ['ciId']),
        })
      }
    >
      <form
        className="flex min-h-0 grow flex-col"
        noValidate
        onReset={formik.handleReset}
        onSubmit={async (event) => {
          const errors = await formik.validateForm()

          const [firstErroredFieldIdAsString] = Object.keys(
            // formik turns objects with numeric keys into arrays
            // https://github.com/jaredpalmer/formik/issues/1365
            Util.pickBy(
              errors.fields ?? {},
              (fieldError) => fieldError != null,
            ),
          )
          const firstErroredFieldId = firstErroredFieldIdAsString
            ? Number(firstErroredFieldIdAsString)
            : null

          if (errors.listingUuid) {
            listingSelectRef.current?.scrollIntoView()
          } else if (errors.amount) {
            amountFormFieldRef.current?.scrollIntoView()
            suggestedAmountLabeldRef.current?.scrollIntoView()
          } else if (errors.quantity) {
            quantityFormFieldRef.current?.scrollIntoView()
          } else if (firstErroredFieldId !== null) {
            if (isTicket) {
              const fieldSetListErrors = errors.fields?.[firstErroredFieldId]

              if (
                fieldSetListErrors &&
                typeof fieldSetListErrors !== 'string'
              ) {
                const [fieldIdInsideFieldSetString] = Object.keys(
                  Util.pickBy(
                    fieldSetListErrors,
                    (fieldError) => fieldError != null,
                  ),
                )
                const fieldIdInsideFieldSet = fieldIdInsideFieldSetString
                  ? Number(fieldIdInsideFieldSetString)
                  : item?.fields[firstErroredFieldId]?.id

                if (fieldIdInsideFieldSet != null) {
                  fieldSetListRefMap?.current[
                    firstErroredFieldId
                  ]?.scrollToField(fieldIdInsideFieldSet)
                }
              }
            } else {
              fieldSetListRefMap?.current[0]?.scrollToField(firstErroredFieldId)
            }
          }

          formik.handleSubmit(event)
        }}
      >
        <div className="flex min-h-0 grow flex-col overflow-y-auto lg:flex-row lg:overflow-y-hidden">
          {hasImages && (
            <ItemViewImageGallery
              key={String(item.id)}
              className={
                'flex-initial overflow-y-visible p-4 lg:w-2/5 lg:overflow-y-auto lg:p-8 [&_>_.ImageMagnifier]:flex-0'
              }
              listing={listing ?? listings[0] ?? null}
              images={item.images}
              onImageLoadError={() => setHasImages(false)}
            />
          )}
          <div
            className={
              'flex min-h-0 shrink-0 grow basis-auto flex-col gap-4 overflow-y-visible px-4 py-4 lg:w-3/5 lg:overflow-y-auto lg:px-8 lg:pt-[25px]'
            }
          >
            <WebUI.Heading className="font-semibold" as="h2">
              {item.name}
            </WebUI.Heading>

            {item.options.fundraisingGoal?.enabled ? (
              <div className="flex flex-col gap-4 rounded-[10px] pt-6 pr-8 pb-4 pl-4 shadow-[0px_0px_10px_#00000029]">
                <WebUI.Text className="font-light text-ds-sm">
                  <span className="font-accent text-ds-lg">
                    {Util.formatAmount(item.total_collected)}
                  </span>{' '}
                  raised of{' '}
                  <strong className="font-semibold">
                    {Util.formatAmount(item.options.fundraisingGoal.value)} Goal
                  </strong>
                </WebUI.Text>
                <WebUI.Bar
                  className="h-4 rounded-[30px] [&_>_.Bar-filledBox]:rounded-[30px]"
                  variant="goal"
                  fill={
                    item.total_collected / item.options.fundraisingGoal.value
                  }
                  filledBarStyles={
                    brandKitColors
                      ? {
                          backgroundImage: `linear-gradient(to right, ${Util.lighten(brandKitColors.primaryButton, 0.5)}, ${brandKitColors.primaryButton})`,
                        }
                      : undefined
                  }
                />
              </div>
            ) : item.options.makeTotalCollectedPublic ? (
              <div className="flex flex-row items-center gap-3">
                <WebUI.PhosphorIcon
                  className="text-tint"
                  icon="flag-pennant-fill"
                />
                <WebUI.Text className="text-ds-sm">
                  {Util.formatAmount(item.total_collected)} raised
                </WebUI.Text>
              </div>
            ) : null}

            {(isAmountTypeOpen ||
              (getItemAmountType(item) === 'recurring' &&
                item.amount_type === 'open')) && (
              <>
                {donationAmountSuggestionsEnabled && (
                  <>
                    <WebUI.FormFieldLabel ref={suggestedAmountLabeldRef}>
                      Suggested Amount(s):
                    </WebUI.FormFieldLabel>
                    <WebUI.ToggleGroup
                      className="group flex flex-col gap-4 [&_.ToggleGroupItem]:w-48"
                      onValueChange={(newValue) => {
                        if (newValue) {
                          setCustomAmountInputMode(false)
                        } else {
                          setCustomAmountInputMode(true)
                        }
                        formik.setFieldValue('amount', newValue)
                      }}
                      aria-invalid={
                        !!formik.errors.amount &&
                        !formik.values.amount &&
                        !customAmountInputMode
                      }
                      defaultValue={cartItem?.amount.toString()}
                    >
                      <div className="flex flex-wrap gap-3">
                        {suggestedDonationAmounts.map((amount, idx) => (
                          <WebUI.ToggleGroupItem
                            key={idx}
                            size="large"
                            variant="outlined"
                            className="aria-checked:shadow-[inset_0_0_0_1px_theme(colors.teal.50)] group-aria-invalid:shadow-[inset_0_0_0_1px_theme(colors.orange.500)]"
                            value={amount.toString()}
                          >
                            {Util.formatAmount(amount)}
                          </WebUI.ToggleGroupItem>
                        ))}
                      </div>
                      {!customAmountInputMode && (
                        <WebUI.ToggleGroupItem
                          size="large"
                          variant="outlined"
                          className="group-aria-invalid:shadow-[inset_0_0_0_1px_theme(colors.orange.500)]"
                          value=""
                        >
                          Custom Amount
                        </WebUI.ToggleGroupItem>
                      )}
                      {!!formik.errors.amount && (
                        <WebUI.FormFieldError className="hidden group-aria-invalid:block">
                          Please select an amount *
                        </WebUI.FormFieldError>
                      )}
                    </WebUI.ToggleGroup>
                  </>
                )}
                {(!donationAmountSuggestionsEnabled ||
                  customAmountInputMode) && (
                  <WebUI.FormField
                    ref={amountFormFieldRef}
                    className="w-48 [&_>_.FormField-caption]:self-center"
                    label={!donationAmountSuggestionsEnabled && 'Enter Amount'}
                    error={formik.errors.amount}
                    caption={
                      item.options.donation?.suggestedAmounts?.minimumAmount &&
                      `Minimum: ${Util.formatAmount(
                        item.options.donation.suggestedAmounts?.minimumAmount,
                      )}`
                    }
                  >
                    <WebUI.AmountInput
                      name="amount"
                      placeholder="$0"
                      value={formik.values.amount}
                      onValueChange={(newAmount) =>
                        formik.setFieldValue('amount', newAmount)
                      }
                      onBlur={formik.handleBlur}
                    />
                  </WebUI.FormField>
                )}
              </>
            )}

            <div className="flex items-center gap-2">
              {!isAmountTypeOpen && (
                <ItemAmountDisplay
                  item={item}
                  listingUuid={listing?.uuid}
                  includeFees={shouldShowFees}
                />
              )}
              {shouldShowFees && (
                <PriceDetailsPopover>
                  {basePriceWithFees.subTotal > 0 ? (
                    <>
                      <div>
                        <span>Item Price</span>
                        {isAmountTypeOpen ? (
                          Util.formatAmount(basePriceWithFees.subTotal)
                        ) : (
                          <ItemAmountDisplay
                            item={item}
                            listingUuid={listing?.uuid}
                          />
                        )}
                      </div>
                      <div>
                        <span>Processing Fee</span>
                        <span>
                          {Util.formatAmount(basePriceWithFees.processingFee)}
                        </span>
                      </div>
                      {basePriceWithFees.perOrderConvenienceFee > 0 && (
                        <div>
                          <span>Per-Order Convenience Fee</span>
                          <span>
                            {Util.formatAmount(
                              basePriceWithFees.perOrderConvenienceFee,
                            )}
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    <WebUI.Text className="max-w-60">
                      After entering your amount, platform fees will be added
                      below.
                    </WebUI.Text>
                  )}
                </PriceDetailsPopover>
              )}
              {isSoldOut && (
                <WebUI.Text variant="danger" className="text-ds-sm">
                  Sold out
                </WebUI.Text>
              )}
            </div>

            {item.options.time?.startTime && (
              <div className="flex max-w-[320px] flex-row gap-2">
                <WebUI.PhosphorIcon
                  className="shrink-0 text-[var(--payer-primary-bg-color)] text-ds-md"
                  icon="calendar-blank-bold"
                />
                <WebUI.Text className="text-ds-sm">
                  {Util.formatDateRange(
                    item.options.time.startTime ?? '',
                    item.options.time.endTime,
                    {
                      safeCharSet: true,
                      dateTimeFormatOptions: {
                        timeZone: item.options.time.timeZone,
                        timeZoneName: 'short',
                      },
                    },
                  )}
                </WebUI.Text>
              </div>
            )}
            {item.options.location && (
              <div className="flex max-w-[320px] flex-row gap-2">
                <WebUI.PhosphorIcon
                  className="shrink-0 text-[var(--payer-primary-bg-color)] text-ds-md text-teal-50"
                  icon="map-pin-bold"
                />
                <WebUI.Text className="text-ds-sm">
                  {item.options.location.address}
                </WebUI.Text>
              </div>
            )}

            {!!item.description && !Util.isMarkdownEmpty(item.description) && (
              <WebUI.MarkdownParagraph
                id={`item-description-${item.id}`}
                markdown={item.description}
              />
            )}
            <WebUI.Separator variant="primary" />
            {item.options.isTaxDeductible && (
              <div className="flex items-center gap-2">
                <WebUI.PhosphorIcon
                  className="text-ds-lg text-yellow-500"
                  icon="seal-check-fill"
                />
                <WebUI.Text className="font-light text-ds-sm">
                  You will receive a tax-deductible receipt for this payment
                </WebUI.Text>
              </div>
            )}

            {item.options.recurring?.enabled &&
              item.options.recurring.options && (
                <>
                  <RecurringInfo data={item.options.recurring.options} />
                  <WebUI.Separator variant="primary" />
                </>
              )}

            {(!isTicket || isNewTicket) &&
            item.allow_quantity &&
            (item.available_quantity !== 0 ||
              (listing?.available_quantity != null &&
                listing.available_quantity > 1)) ? (
              <>
                <WebUI.FormField
                  ref={quantityFormFieldRef}
                  required
                  label={`Qty${
                    item.options.makeAvailableQuantityPublic &&
                    item.available_quantity != null
                      ? ` (${item.available_quantity} left)`
                      : ''
                  }:`}
                  error={formik.errors.quantity}
                >
                  <WebUI.NumberInput
                    name="quantity"
                    className="w-16"
                    placeholder="0"
                    value={formik.values.quantity}
                    onChange={async (event) => {
                      await formik.handleChange(event)
                      if (!waitlistEnabled) {
                        formik.validateField('quantity')
                      }
                    }}
                    onBlur={async (event) => {
                      await formik.handleBlur(event)
                      if (!waitlistEnabled) {
                        formik.validateField('quantity')
                      }
                      if (isTicket) {
                        const newQty = Number.parseInt(event.target.value)
                        formik.setFieldValue(
                          'fields',
                          [
                            ...(Array.isArray(formik.values.fields)
                              ? formik.values.fields
                              : []),
                            ...Array.from({
                              length:
                                newQty -
                                (Array.isArray(formik.values.fields)
                                  ? formik.values.fields.length
                                  : 0),
                            }).fill(
                              Object.fromEntries(
                                item.fields.map((f) => [f.id, '']),
                              ),
                            ),
                          ].slice(0, newQty),
                        )
                      }
                    }}
                  />
                </WebUI.FormField>
                {item.options.perPersonMaxQuantity?.enabled && (
                  <WebUI.Text className="text-ds-xs">
                    Limit: {item.options.perPersonMaxQuantity?.value}
                  </WebUI.Text>
                )}
                <WebUI.Separator variant="primary" />
              </>
            ) : cartItem ? (
              <>
                <WebUI.Text className="font-bold">
                  Quantity
                  {cartItem && (
                    <>
                      : <span className="text-teal-50">1</span>
                    </>
                  )}
                </WebUI.Text>
                <WebUI.Separator variant="primary" />
              </>
            ) : null}

            {item.options.quantityDiscount?.enabled && (
              <DealDisclaimer
                quantityDiscount={item.options.quantityDiscount}
              />
            )}

            {listings.length > 0 && (
              <>
                <ListingSelect
                  ref={listingSelectRef}
                  invalid={!!formik.errors.listingUuid}
                  initialOptionValues={listing?.optionValues}
                  optionKeysOrdered={
                    item.options.variants?.options.map((o) => o.key) ?? []
                  }
                  listings={
                    publicCollection.hideSoldOutItems
                      ? listings.filter(
                          (i) =>
                            i.available_quantity == null ||
                            i.available_quantity > 0,
                        )
                      : listings
                  }
                  onListingUuidChange={(newListingUuid) =>
                    formik.setFieldValue('listingUuid', newListingUuid)
                  }
                />
                <WebUI.Separator variant="primary" />
              </>
            )}
            {isTicket && !isSoldOut && (
              <div className="flex flex-col gap-4">
                <WebUI.Text className="font-semibold">
                  Ticket Information
                </WebUI.Text>
                <WebUI.Text className="text-ds-sm">
                  Your QR code tickets will be emailed to you with your payment
                  receipt. Tickets will also be emailed to any attendee emails
                  you include below.
                </WebUI.Text>
              </div>
            )}

            {isNewTicket && !isSoldOut ? (
              <div className="flex flex-col gap-9">
                {Array.from({length: formik.values.quantity}).map(
                  (_, index) => (
                    <React.Fragment key={index}>
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-row justify-between">
                          <WebUI.Text className="font-semibold">
                            Ticket #{index + 1}
                          </WebUI.Text>
                          {index > 0 && (
                            <WebUI.Button
                              className="text-ds-xs"
                              variant="link"
                              iconBefore={
                                <WebUI.PhosphorIcon
                                  width={20}
                                  icon="copy-simple"
                                />
                              }
                              onClick={() => {
                                formik.setFieldValue(
                                  `fields.${index}`,
                                  formik.values.fields[index - 1],
                                )
                                setFieldSetListKey(Util.makeShortId())
                              }}
                            >
                              Copy Previous
                            </WebUI.Button>
                          )}
                        </div>
                        <FieldSetList
                          key={fieldSetListKey}
                          ref={(fieldRef) => {
                            fieldSetListRefMap.current[index] = fieldRef
                          }}
                          className="h-auto flex-0 overflow-visible"
                          EmptyStateViewComponent={React.Fragment}
                          fieldSets={item.options.fieldSets as any}
                          fields={item.fields}
                          defaultValues={
                            (formik.values.fields[index] ??
                              initialFields) as any
                          }
                          onValuesChange={(fieldId, fieldValue) => {
                            if (!formik.values.fields[index]) {
                              formik.setFieldValue(
                                `fields.${index}`,
                                initialFields,
                              )
                            }
                            formik.setFieldValue(
                              `fields.${index}.${fieldId}`,
                              fieldValue,
                            )
                          }}
                          errors={
                            formik.errors.fields?.[index] as Record<
                              number,
                              string
                            >
                          }
                        />
                      </div>

                      <WebUI.Separator />
                    </React.Fragment>
                  ),
                )}
              </div>
            ) : isSoldOut ? null : (
              <FieldSetList
                ref={(fieldRef) => {
                  fieldSetListRefMap.current[0] = fieldRef
                }}
                className="h-auto flex-0 overflow-visible"
                EmptyStateViewComponent={React.Fragment}
                fieldSets={item.options.fieldSets ?? undefined}
                fields={item.fields}
                defaultValues={formik.values.fields as any}
                onValuesChange={(fieldId, fieldValue) =>
                  formik.setFieldValue(`fields.${fieldId}`, fieldValue)
                }
                errors={formik.errors.fields as Record<number, string>}
              />
            )}
            {waitlistEnabled && (
              <>
                <WebUI.Text>Join the waitlist</WebUI.Text>
                <WebUI.FormField
                  required
                  label="First Name"
                  error={formik.errors.first_name}
                >
                  <WebUI.Input
                    name="first_name"
                    className="max-w-[390px]"
                    placeholder="First Name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
                <WebUI.FormField
                  required
                  label="Last Name"
                  error={formik.errors.last_name}
                >
                  <WebUI.Input
                    name="last_name"
                    className="max-w-[390px]"
                    placeholder="Last Name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
                <WebUI.FormField
                  required
                  label="Email Address"
                  error={formik.errors.email}
                >
                  <WebUI.Input
                    name="email"
                    className="max-w-[390px]"
                    placeholder="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
                <WebUI.FormField
                  required
                  label="Confirm Email Address"
                  error={formik.errors.confirm_email}
                >
                  <WebUI.Input
                    name="confirm_email"
                    className="max-w-[390px]"
                    placeholder="Confirm Email Address"
                    value={formik.values.confirm_email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
                {item.allow_quantity && (
                  <WebUI.FormField
                    required
                    label="Desired Quantity"
                    error={formik.errors.quantity_desired}
                  >
                    <WebUI.NumberInput
                      name="quantity_desired"
                      className="w-16"
                      placeholder="0"
                      value={formik.values.quantity_desired}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </WebUI.FormField>
                )}
              </>
            )}
          </div>
        </div>

        <div className="flex flex-row items-center justify-end gap-4 border-t p-4">
          <PayerButton
            className="grow sm:grow-0"
            type="submit"
            size="large"
            variant={preview ? 'themed-secondary' : 'themed-primary'}
            disabled={preview === true}
            loading={formik.isSubmitting}
          >
            {(() => {
              if (preview) {
                return `Available ${Util.formatDateAs(
                  new Date(publicCollection.timing?.opens ?? ''),
                )}`
              }
              if (isSoldOut) {
                return waitlistEnabled ? 'Join the Waitlist' : 'Sold Out'
              }
              return cartItem
                ? 'Save'
                : `Add to Cart${
                    (!item.options.variants?.enabled || !!listing) &&
                    itemTotalPriceWithFees.subTotal
                      ? ` (${Util.formatAmount(itemTotalPriceWithFees.total)})`
                      : ''
                  }`
            })()}
          </PayerButton>
        </div>
      </form>

      <WebUI.ModalCloseButton />

      <JoinWaitlistConfirmationAlert
        ref={joinWaitlistConfirmationModalRef}
        description={item.options.waitlist?.customMessage}
        onConfirm={() => modalRef.current?.hide()}
      />
    </WebUI.Modal>
  )
}

// MARK: – RecurringInfo

interface RecurringInfoProps extends React.ComponentPropsWithoutRef<'div'> {
  annotationHidden?: boolean
  data: Api.RecurringPaymentContract['options']
}

const RecurringInfo = ({
  annotationHidden,
  data,
  className,
  ...restProps
}: RecurringInfoProps) => {
  const recurringPaymentLabels = RecurringPaymentFormatter.getLabels(data)

  return (
    <div
      className={WebUI.cn(
        'flex flex-col gap-4 font-normal text-ds-sm text-gray750',
        className,
      )}
      {...restProps}
    >
      <RecurringPaymentIndicator />
      <p>
        <span className="text-gray400">Starting</span>:{' '}
        {recurringPaymentLabels.start}
        <br />
        <span className="text-gray400">Repeating Every</span>:{' '}
        {recurringPaymentLabels.repeat}
        <br />
        <span className="text-gray400">Ending</span>:{' '}
        {recurringPaymentLabels.end}
      </p>
      {!annotationHidden && (
        <RecurringOptionsAnnotation
          className="text-ds-sm"
          noBold
          recurringOptions={data}
        />
      )}
    </div>
  )
}

// MARK: – JoinWaitListConfirmationModal

interface WithdrawalConfirmationAlertProps extends WebUI.AlertProps {
  description?: string
  onConfirm: () => void
}

const JoinWaitlistConfirmationAlert = React.forwardRef<
  WebUI.DialogInstance,
  WithdrawalConfirmationAlertProps
>(({description, onConfirm, ...restProps}, forwardedRef) => (
  <WebUI.Alert
    ref={forwardedRef}
    aria-label="JoinWaitlist confirmation"
    {...restProps}
  >
    <WebUI.AlertHeader>You’ve been added to the waitlist</WebUI.AlertHeader>
    <WebUI.AlertContentView
      text={
        description ??
        'If you have questions about this waitlist, please contact your group organizer.'
      }
      actions={
        <WebUI.AlertCancelButton variant="default" onClick={onConfirm}>
          Close
        </WebUI.AlertCancelButton>
      }
    />
  </WebUI.Alert>
))

// MARK: - PriceDetailsPopover

interface PriceDetailsPopoverProps extends Omit<NextPopoverProps, 'children'> {
  children?: React.ReactNode
}

export const PriceDetailsPopover = React.forwardRef<
  NextPopoverInstance,
  PriceDetailsPopoverProps
>(({children, placement = 'bottom-start', ...restProps}) => {
  return (
    <NextPopover placement={placement} {...restProps}>
      <NextPopoverDisclosure
        className={WebUI.cn(
          'cursor-pointer text-ds-xs text-gray-500 underline decoration-dashed underline-offset-4',
        )}
        render={<WebUI.Text />}
      >
        Price details
      </NextPopoverDisclosure>

      <NextPopoverContent
        className={WebUI.cn(
          'gap-2 rounded-extended p-6 font-medium text-ds-sm',
          '[&_div]:flex [&_div]:justify-between [&_div]:gap-6',
          'z-[9999]',
        )}
        portal={true}
        preventBodyScroll={false}
      >
        <div className="flex min-w-[240px] flex-col gap-2 bg-white">
          {children}
        </div>
      </NextPopoverContent>
    </NextPopover>
  )
})

export default ItemViewPage

// MARK: – DealDisclaimer

interface DealDisclaimerProps extends React.ComponentPropsWithoutRef<'div'> {
  quantityDiscount: Api.TabItemQuantityDiscount
}

const DealDisclaimer = ({
  quantityDiscount,
  className,
  ...restProps
}: DealDisclaimerProps) => {
  return (
    <span
      className={WebUI.cn('text-ds-xs text-orange-500', className)}
      {...restProps}
    >
      <span className="font-bold">
        DEAL: {formatQuantityDiscount(quantityDiscount)}
      </span>
      <br />
      <span>Discount will be applied in cart</span>
    </span>
  )
}
