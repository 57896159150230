import * as Yup from 'yup'
import React, {useRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useCheckEmailExistenceMutation,
  useUpdatePasswordMutation,
  useUpdateUserMutation,
} from '@cheddarup/api-client'
import {useFormik} from '@cheddarup/react-util'
import {
  AccountSettingsContentCard,
  AccountSettingsContentLayout,
} from './components/AccountSettingsContentLayouts'
import {INVALID_EMAIL_STATUSES} from 'src/helpers/email-status-helpers'
import {guessError} from 'src/helpers/error-utils'
import {
  InquireVerificationCode,
  InquireVerificationCodeInstance,
} from 'src/components/InquireVerificationCode'

const EmailAndPasswordPage = () => {
  return (
    <AccountSettingsContentLayout heading="Email and Password">
      <InquireVerificationCode>
        {(verificationHelpers) => (
          <>
            <EmailForm verificationHelpers={verificationHelpers} />
            <PasswordForm verificationHelpers={verificationHelpers} />
          </>
        )}
      </InquireVerificationCode>
    </AccountSettingsContentLayout>
  )
}

// MARK: – EmailForm

interface EmailFormProps extends React.ComponentPropsWithoutRef<'form'> {
  verificationHelpers: InquireVerificationCodeInstance
}

const EmailForm = ({verificationHelpers, ...restProps}: EmailFormProps) => {
  const sessionQuery = api.auth.session.useQuery()
  const updateUserMutation = useUpdateUserMutation()
  const checkEmailExistenceMutation = useCheckEmailExistenceMutation()
  const growlActions = WebUI.useGrowlActions()
  const disclosureRef = useRef<WebUI.DisclosureInstance>(null)

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid format').required('Required'),
    }),
    initialValues: {
      email: sessionQuery.data?.user.email ?? '',
    },
    onSubmit: async (values, formikHelpers) => {
      if (sessionQuery.data?.user.email === values.email) {
        disclosureRef.current?.hide()
        return
      }

      try {
        const checkEmailExistenceRes =
          await checkEmailExistenceMutation.mutateAsync({
            body: {email: values.email},
          })
        const isValidEmail = !INVALID_EMAIL_STATUSES.includes(
          checkEmailExistenceRes.status,
        )

        if (!isValidEmail) {
          formikHelpers.setFieldError(
            'email',
            'The email entered is invalid. Please enter a valid email address.',
          )
          return
        }
        if (checkEmailExistenceRes.exists) {
          formikHelpers.setFieldError(
            'email',
            'The email entered is already in use. Please enter another email address.',
          )
          return
        }

        const {verificationCode} = await verificationHelpers.verifyPhone()

        await updateUserMutation.mutateAsync({
          body: {
            email: values.email,
            security: {token: verificationCode},
          },
        })

        disclosureRef.current?.hide()
      } catch (err) {
        growlActions.show('error', {
          title: 'Error',
          body: guessError(err).message,
        })
      }
    },
  })

  return (
    <WebUI.Disclosure ref={disclosureRef}>
      {(disclosure) => (
        <AccountSettingsContentCard
          aria-disabled={!disclosure.visible}
          as="form"
          heading="Email"
          onReset={formik.handleReset}
          onSubmit={formik.handleSubmit}
          {...restProps}
        >
          <WebUI.FormField
            required
            label="Email"
            error={formik.errors.email}
            suffix={
              <WebUI.DisclosureButton
                className="text-ds-sm"
                variant="link"
                arrow={false}
              >
                Reset
              </WebUI.DisclosureButton>
            }
          >
            <WebUI.Input
              name="email"
              disabled={!disclosure.visible}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>

          <WebUI.DisclosureContent>
            <div className="flex flex-row gap-8">
              <WebUI.Button type="submit" loading={formik.isSubmitting}>
                Save
              </WebUI.Button>
              <WebUI.Button
                className="text-ds-sm"
                type="reset"
                variant="link"
                onClick={() => disclosure.hide()}
              >
                Cancel
              </WebUI.Button>
            </div>
          </WebUI.DisclosureContent>
        </AccountSettingsContentCard>
      )}
    </WebUI.Disclosure>
  )
}

// MARK: – PasswordForm

interface PasswordFormProps extends React.ComponentPropsWithoutRef<'form'> {
  verificationHelpers: InquireVerificationCodeInstance
}

const PasswordForm = ({
  verificationHelpers,
  ...restProps
}: PasswordFormProps) => {
  const updatePasswordMutation = useUpdatePasswordMutation()
  const growlActions = WebUI.useGrowlActions()
  const disclosureRef = useRef<WebUI.DisclosureInstance>(null)

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      currentPassword: Yup.string().required('Required'),
      newPassword: Yup.string()
        .required('Required')
        .min(6, 'Include 6 characters')
        .matches(/\d/, 'Include a number')
        .notOneOf([Yup.ref('currentPassword')], 'Must be different'),
      newPasswordConfirmation: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('newPassword')], 'Must be equal'),
    }),
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    onSubmit: async (values) => {
      try {
        const {verificationCode} = await verificationHelpers.verifyPhone()
        await updatePasswordMutation.mutateAsync({
          body: {
            user: {
              current_password: values.currentPassword,
              password: values.newPassword,
              password_confirmation: values.newPasswordConfirmation,
            },
            ...(verificationCode && {
              security: {token: verificationCode},
            }),
          },
        })

        disclosureRef.current?.hide()

        growlActions.show('success', {
          title: 'Success',
          body: 'Your password was successfully updated',
        })
      } catch (err) {
        growlActions.show('error', {
          title: 'Error',
          body: guessError(err).message,
        })
      }
    },
  })

  return (
    <WebUI.Disclosure ref={disclosureRef}>
      {(disclosure) => (
        <AccountSettingsContentCard
          as="form"
          heading="Password"
          onReset={formik.handleReset}
          onSubmit={formik.handleSubmit}
          {...restProps}
        >
          {!disclosure.visible && (
            <WebUI.FormField
              label="Password"
              suffix={
                <WebUI.DisclosureButton
                  className="text-ds-sm"
                  variant="link"
                  arrow={false}
                >
                  Reset
                </WebUI.DisclosureButton>
              }
            >
              <WebUI.Input type="password" disabled value="•••••••" />
            </WebUI.FormField>
          )}

          <WebUI.DisclosureContent>
            <div className="flex flex-col gap-6">
              <WebUI.FormField
                required
                label="Current Password"
                error={formik.errors.currentPassword}
              >
                <WebUI.Input
                  name="currentPassword"
                  type="password"
                  placeholder="Required"
                  value={formik.values.currentPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
              <WebUI.PasswordFormFieldInput
                required
                label="New Password"
                error={formik.errors.newPassword}
                caption="Passwords must contain at least 6 characters and 1 number."
                name="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <WebUI.FormField
                required
                label="Confirm New Password"
                error={formik.errors.newPasswordConfirmation}
              >
                <WebUI.Input
                  name="newPasswordConfirmation"
                  type="password"
                  placeholder="Required"
                  value={formik.values.newPasswordConfirmation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>

              <div className="flex flex-row gap-8">
                <WebUI.Button type="submit" loading={formik.isSubmitting}>
                  Save
                </WebUI.Button>
                <WebUI.Button
                  className="text-ds-sm"
                  type="reset"
                  variant="link"
                  onClick={() => disclosure.hide()}
                >
                  Cancel
                </WebUI.Button>
              </div>
            </div>
          </WebUI.DisclosureContent>
        </AccountSettingsContentCard>
      )}
    </WebUI.Disclosure>
  )
}

export default EmailAndPasswordPage
