import React, {useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {bannedWordIncludedIn} from 'src/helpers/inputHelpers'
import {LinkButton} from 'src/components/LinkButton'
import * as Util from '@cheddarup/util'
import {SharpImage} from 'src/components/SharpImage'

import {CollectionAttachments, PayerInfoPanels} from './PayerInfoPanels'
import usePublicCollection from '../hooks/usePublicCollection'

export interface CollectionOverviewProps
  extends React.ComponentPropsWithoutRef<'div'> {
  detailsHidden?: boolean
}

export const CollectionOverview: React.FC<CollectionOverviewProps> = ({
  detailsHidden = false,
  className,
  ...restProps
}) => {
  const media = WebUI.useMedia()
  const {publicCollection} = usePublicCollection()

  const descriptionMaxLength = media.sm ? Number.POSITIVE_INFINITY : 500
  const hasMoreDetails =
    (!!publicCollection.description &&
      publicCollection.description.length > descriptionMaxLength) ||
    publicCollection.infoBlockSettings?.faqs?.enabled ||
    publicCollection.infoBlockSettings?.location?.enabled ||
    publicCollection.infoBlockSettings?.promoteSharing?.enabled ||
    publicCollection.infoBlockSettings?.nonProfitStatus?.enabled ||
    publicCollection.infoBlockSettings?.time?.enabled ||
    publicCollection.infoBlockSettings?.payerList?.enabled

  const collectionHeaderImages = useMemo(
    () =>
      Util.sort(publicCollection.headerImages ?? []).asc(
        (hImg) =>
          publicCollection.imageCarouselIds?.indexOf(hImg.id) ??
          Number.MAX_SAFE_INTEGER,
      ),
    [publicCollection.headerImages, publicCollection.imageCarouselIds],
  )

  return (
    <WebUI.Panel
      className={WebUI.cn('flex flex-col', className)}
      variant="capsule"
      {...restProps}
    >
      {((publicCollection.headerImages &&
        publicCollection.headerImages.length > 0) ||
        (publicCollection.infoBlockSettings?.video?.enabled &&
          publicCollection.infoBlockSettings.video.link)) && (
        <CollectionBannerCarousel
          headerImages={collectionHeaderImages}
          videoLink={
            publicCollection.infoBlockSettings?.video?.enabled
              ? publicCollection.infoBlockSettings.video.link
              : null
          }
        />
      )}
      <div className="flex flex-col gap-4 px-6 py-6 sm:px-7 sm:py-8">
        <CollectionSummary hasMoreDetails={hasMoreDetails} />
        {!detailsHidden && !media.lg && (
          <div className="flex flex-row [&_>_.Button_>_.Button-content]:text-teal-50">
            {hasMoreDetails ? (
              <PayerInfoPanelsModal
                disclosure={
                  <WebUI.DialogDisclosure
                    size="large"
                    variant="secondary"
                    roundness="capsule"
                  >
                    More details
                  </WebUI.DialogDisclosure>
                }
              />
            ) : (
              <LinkButton
                variant="link"
                roundness="capsule"
                relative="path"
                preserveSearch
                to="help"
              >
                Questions? Contact Organizer
              </LinkButton>
            )}
          </div>
        )}
      </div>
    </WebUI.Panel>
  )
}

// MARK: – CollectionBannerCarousel

interface CollectionBannerCarouselProps
  extends React.ComponentPropsWithoutRef<'div'> {
  headerImages: Api.S3Image[]
  videoLink: string | null
}

const CollectionBannerCarousel: React.FC<CollectionBannerCarouselProps> = ({
  headerImages,
  videoLink,
  className,
  ...restProps
}) => {
  return (
    <WebUI.Carousel
      className={WebUI.cn('bg-trueBlack', className)}
      options={{loop: true}}
      {...restProps}
    >
      <WebUI.CarouselContent className="aspect-video sm:min-h-64">
        {headerImages.map((headerImage) => (
          <WebUI.CarouselItem
            key={headerImage.id}
            className="flex items-center justify-center"
          >
            <SharpImage
              alt="Collection banner"
              width="100%"
              image={headerImage}
              edits={{resize: {fit: 'contain'}}}
              errorFallback={null}
            />
          </WebUI.CarouselItem>
        ))}

        {!!videoLink && (
          <WebUI.CarouselItem className="bg-trueBlack">
            <WebUI.VideoPlayer
              className="[&_>_div]:!flex [&_>_div]:!items-center [&_>_div]:!justify-center flex items-center justify-center"
              width="100%"
              height="100%"
              url={videoLink}
            />
          </WebUI.CarouselItem>
        )}
      </WebUI.CarouselContent>
      {[...headerImages, videoLink].filter((el) => !!el).length > 1 && (
        <WebUI.CarouselStepper />
      )}
    </WebUI.Carousel>
  )
}

// MARK: – CollectionSummary

interface CollectionSummaryProps extends React.ComponentPropsWithoutRef<'div'> {
  descriptionMaxLength?: number
  hasMoreDetails?: boolean
}

const CollectionSummary: React.FC<CollectionSummaryProps> = ({
  descriptionMaxLength = Number.POSITIVE_INFINITY,
  hasMoreDetails,
  className,
  ...restProps
}) => {
  const {publicCollection} = usePublicCollection()
  const media = WebUI.useMedia()

  return (
    <div className={WebUI.cn('flex flex-col gap-3', className)} {...restProps}>
      <WebUI.Heading className="font-accentAlt text-h-6 [font-stretch:expanded] sm:text-h-3">
        {publicCollection.name}
      </WebUI.Heading>
      <CollectionDescription maxLength={descriptionMaxLength} />
      {(media.lg || !hasMoreDetails) && (
        <CollectionAttachments className="text-ds-sm" />
      )}
    </div>
  )
}

// MARK: – CollectionDescription

interface CollectionDescriptionProps
  extends React.ComponentPropsWithoutRef<'div'> {
  maxLength?: number
}

const CollectionDescription: React.FC<CollectionDescriptionProps> = ({
  maxLength,
  className,
  ...restProps
}) => {
  const {publicCollection} = usePublicCollection()

  if (
    !publicCollection.description ||
    Util.isMarkdownEmpty(publicCollection.description)
  ) {
    return null
  }

  if (bannedWordIncludedIn(publicCollection.description)) {
    return publicCollection.userManagesCollection ? (
      <div
        className={WebUI.cn('rounded bg-depr-grey-200 p-4', className)}
        {...restProps}
      >
        Your collection description contains restricted language (mention of
        another payment platform), and it will not appear on your collection
        page. This field is closely monitored and if a workaround is entered,
        your collection may be at risk of being shut down, with payments auto
        refunded.
      </div>
    ) : null
  }

  return (
    <WebUI.MarkdownParagraph
      className={WebUI.cn(
        'min-h-0 font-light text-ds-base sm:text-ds-md',
        className,
      )}
      markdown={
        maxLength == null
          ? publicCollection.description
          : Util.truncate(publicCollection.description, {length: maxLength})
      }
      {...restProps}
    />
  )
}

// MARK: – PayerInfoPanelsModal

interface PayerInfoPanelsModalProps extends WebUI.ModalProps {}

const PayerInfoPanelsModal = React.forwardRef<
  WebUI.DialogInstance,
  PayerInfoPanelsModalProps
>(({initialVisible = false, className, ...restProps}, forwardedRef) => (
  <WebUI.Modal
    ref={forwardedRef}
    aria-label="Collection info blocks"
    className={WebUI.cn(
      '[&_>_.ModalContentView]:!overflow-auto [&_>_.ModalContentView]:bg-gray100',
      className,
    )}
    initialVisible={initialVisible}
    {...restProps}
  >
    <div className="sticky top-0 z-10 flex justify-end bg-trueWhite px-2 py-4">
      <WebUI.ModalCloseButton
        className="relative top-auto right-auto"
        icon="x-bold"
      />
    </div>
    <div className="flex flex-col gap-4">
      <WebUI.Panel
        className="border-none px-6 pb-6"
        as={CollectionSummary}
        hasMoreDetails
      />
      <PayerInfoPanels />
    </div>
  </WebUI.Modal>
))
