import * as WebUI from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'
import * as Util from '@cheddarup/util'
import {useLocation, useNavigate} from 'react-router-dom'
import {
  api,
  useCheckSubscriptionMutation,
  useUpdateSubscriptionMutation,
} from '@cheddarup/api-client'
import React from 'react'
import ManagersLogoIcon from 'src/images/ManagersLogoIcon.svg'
import {StringParam, useQueryParam} from 'use-query-params'
import CUCube from 'src/images/CUCube.svg'
import {trackUserSubscriptionChangeEvent} from 'src/helpers/analytics'

const PlansPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [recommendedPlan] = useQueryParam('recommendedPlan', StringParam)
  const sessionQuery = api.auth.session.useQuery()
  const ownSubscriptionQuery = api.subscriptions.invoices.useQuery()
  const {data: validPromoCodes} = api.subscriptions.partnerPromoCode.useQuery(
    undefined,
    {select: (promoCodes) => promoCodes.filter((pc) => pc.valid)},
  )
  const checkSubscriptionMutation = useCheckSubscriptionMutation()
  const updateSubscriptionMutation = useUpdateSubscriptionMutation()
  const growlActions = WebUI.useGrowlActions()

  return (
    <NextUI.Dialog open>
      <NextUI.ModalContent
        className="w-full max-w-screen-xl aria-disabled:pointer-events-none"
        disabled={updateSubscriptionMutation.isPending || undefined}
        onClose={() => {
          // hack for react-native WebView to detect url change
          window.location.hash = Util.makeShortId()
          navigate({pathname: '../..', search: location.search})
        }}
      >
        <div className="relative flex flex-col gap-8 px-6 py-14 lg:px-10">
          <div className="-z-10 absolute inset-x-0 top-56 h-[calc(100%-14rem)] bg-grey-100" />
          <div className="flex flex-col gap-2 font-normal">
            <NextUI.DialogHeading className="text-h-7">
              Your Current Plan:{' '}
              {ownSubscriptionQuery.data?.plan.name ?? (
                <WebUI.Skeleton width={40} />
              )}
            </NextUI.DialogHeading>
            <NextUI.Text className="text-ds-md">
              {recommendedPlan ? (
                <>
                  Based on the paid features you’ve chosen, we recommend the{' '}
                  <span className="font-black">
                    {Util.capitalize(recommendedPlan)} Plan
                  </span>
                  .
                </>
              ) : (
                'We offer simple, affordable plans that you can start and stop at any time.'
              )}
            </NextUI.Text>
          </div>

          {validPromoCodes && validPromoCodes?.length > 0 && (
            <WebUI.Well
              role="alert"
              className="flex items-center gap-7 bg-trueWhite px-8 py-4 shadow-500"
            >
              <WebUI.Image alt="" src={CUCube} />
              <NextUI.Text className="font-normal">
                <span className="font-black text-orange-500">
                  Partner Discount Alert!
                </span>{' '}
                {validPromoCodes[0]?.label} enjoy an additional{' '}
                {validPromoCodes.map((code, index) => (
                  <React.Fragment key={code.code}>
                    <span className="font-black">{code.description}</span>
                    {index < validPromoCodes.length - 1 ? ' or ' : ' '}
                  </React.Fragment>
                ))}
                at checkout
              </NextUI.Text>
            </WebUI.Well>
          )}

          <div className="flex flex-0 flex-col divide-y overflow-x-hidden sm:rounded-b-[30px] sm:border">
            <div className="flex flex-col *:flex-1 max-sm:gap-5 max-sm:*:border sm:flex-row sm:divide-x sm:overflow-x-auto">
              <PlanCard
                plan="basic"
                listTitle="Free forever"
                onPlanSelect={async () => {
                  const checkRes = await checkSubscriptionMutation.mutateAsync({
                    body: {plan: 'free'},
                  })

                  if (checkRes.results.some((result) => !!result.error)) {
                    navigate('basic/error')
                  } else if (
                    checkRes.results.some((result) => !!result.warning) ||
                    sessionQuery.data?.capabilities.plan === 'team'
                  ) {
                    navigate('downgrade/basic')
                  } else if (sessionQuery.data?.capabilities.plan === 'pro') {
                    navigate(
                      `pause/${sessionQuery.data.capabilities.subscribed_to_team ? 'basic' : 'pro'}`,
                    )
                  } else {
                    try {
                      const newPlan =
                        await updateSubscriptionMutation.mutateAsync({
                          body: {plan: 'free', source_id: ''},
                        })
                      trackUserSubscriptionChangeEvent(newPlan)
                      growlActions.show('success', {
                        body: "You've been downgraded",
                        title: 'Back to Basics',
                      })
                    } catch (err: any) {
                      let errMsg = err.message || err.response?.data?.error
                      if (errMsg === 'tabs_have_balances') {
                        errMsg =
                          'You must withdraw your balance prior to downgrading'
                      }

                      if (errMsg) {
                        growlActions.show('error', {
                          title: 'Error',
                          body: errMsg,
                        })
                      }
                    }
                  }
                }}
              >
                <li>Unlimited pages</li>
                <li>Up to 5 items per page</li>
                <li>Up to 1 form or sign up per page</li>
                <li>
                  Unlimited, free withdrawals to bank account or gift card links
                </li>
                <li>Peer-to-peer fundraising</li>
                <li>Multiple ways to share</li>
                <li>
                  Payments by credit card, cash, Link, Apple Pay & Google Pay
                  with industry-low{' '}
                  <FeesTooltip plan="basic" creditCardFee="3.95% + $0.95">
                    fees
                  </FeesTooltip>
                </li>
              </PlanCard>
              <PlanCard
                plan="pro"
                listTitle="Everything in Basic, plus:"
                onPlanSelect={async () => {
                  if (sessionQuery.data?.capabilities.subscribed_to_team) {
                    const checkResults =
                      await checkSubscriptionMutation.mutateAsync({
                        body: {plan: 'free'},
                      })

                    if (checkResults.results.some((r) => !!r.error)) {
                      navigate('basic/error')
                    } else if (
                      checkResults.results.some(
                        (r) => r.warning === 'managers_will_be_deleted',
                      )
                    ) {
                      navigate('pro/warning')
                    } else {
                      navigate('downgrade/pro')
                    }
                  } else {
                    navigate(
                      {pathname: 'pro', search: location.search},
                      {state: location.state},
                    )
                  }
                }}
              >
                <li>Unlimited items, forms and sign ups per page</li>
                <li>Unlimited waivers per page</li>
                <li>
                  Selling features including shipping, item variations,
                  discounts codes, and more
                </li>
                <li>eSignatures and file uploads</li>
                <li>Fundraising Goal</li>
                <li>Custom Receipts</li>
                <li>Payments by eChecks</li>
                <li>
                  <FeesTooltip
                    plan="pro"
                    creditCardFee="3.59% + $0.59"
                    eChecksFee="0.75% (min $1)"
                  >
                    Lower fees
                  </FeesTooltip>
                </li>
              </PlanCard>
              <PlanCard
                plan="team"
                listTitle="Everything in Pro, plus:"
                onPlanSelect={() => {
                  if (
                    isMonthlyPlan(
                      ownSubscriptionQuery.data?.plan.name ?? 'basic',
                    )
                  ) {
                    navigate(
                      {pathname: 'team-upgrade', search: location.search},
                      {state: location.state},
                    )
                  } else if (
                    !sessionQuery.data?.capabilities.subscribed_to_team
                  ) {
                    navigate(
                      {pathname: 'team-upgrade', search: location.search},
                      {state: location.state},
                    )
                  }
                }}
              >
                <li>Unlimited managers</li>
                <li>Ticketing</li>
                <li>Group website and custom branding</li>
                <li>Recurring payments</li>
                <li>
                  Registration features including dynamic forms, waitlists, and
                  more
                </li>
                <li>Verified Non-Profit badge and tax-deductible receipts</li>
                <li>Advanced account-wide reporting</li>
                <li>Integrations</li>
                <li>
                  <FeesTooltip
                    plan="team"
                    creditCardFee="3.59% + $0.59"
                    eChecksFee="Flat $0.95"
                  >
                    Lowest fees
                  </FeesTooltip>
                </li>
              </PlanCard>
              <PlanCard
                plan="partner"
                listTitle="Limitless growth and oversight"
                ctaButtonTitle="Talk to Us"
                onPlanSelect={() => {
                  window
                    .open('https://my.cheddarup.com/contact/', '_blank')
                    ?.focus()
                }}
              >
                <li>Custom forms libraries and templates</li>
                <li>Partner insight dashboard</li>
                <li>Custom sign up page</li>
                <li>Auto-populated account underwriting</li>
                <li>Dedicated account manager</li>
                <li>VIP onboarding and training</li>
              </PlanCard>
            </div>

            <div className="flex items-center justify-center bg-trueWhite py-6">
              <NextUI.NextAnchorButton
                size="md"
                href="https://www.cheddarup.com/pricing/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Compare All Features by Plan
                <WebUI.PhosphorIcon
                  className="text-ds-lg"
                  icon="arrow-square-up-right-bold"
                />
              </NextUI.NextAnchorButton>
            </div>
          </div>
        </div>
        <NextUI.ModalCloseButton />
      </NextUI.ModalContent>
    </NextUI.Dialog>
  )
}

// MARK: – PlanCard

const availablePlans = ['basic', 'pro', 'team', 'partner'] as const

type PlanCardPlan = (typeof availablePlans)[number]

interface PlanCardProps extends React.ComponentPropsWithoutRef<'div'> {
  plan: PlanCardPlan
  ctaButtonTitle?: React.ReactNode
  listTitle: React.ReactNode
  onPlanSelect?: () => void
}

const PlanCard = ({
  plan,
  ctaButtonTitle,
  listTitle,
  onPlanSelect,
  className,
  children,
  ...restProps
}: PlanCardProps) => {
  const media = WebUI.useMedia()
  const [recommendedPlan] = useQueryParam('recommendedPlan', StringParam)
  const isMonthlyPlanQuery = api.subscriptions.invoices.useQuery(undefined, {
    select: (ownSubscription) => isMonthlyPlan(ownSubscription.plan.name),
  })
  const ownPlanQuery = api.auth.session.useQuery(undefined, {
    select: (session) => getPlanCardPlan(session),
  })
  const isPausedProQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.plan === 'pause',
  })

  const isPaused = plan === 'pro' && !!isPausedProQuery.data

  const ownPlan = ownPlanQuery.data ?? 'basic'
  const planIsMonthly = ownPlan === plan && !!isMonthlyPlanQuery.data

  const isCurrent = ownPlan === plan

  const isDowngrade =
    availablePlans.indexOf(ownPlan) > availablePlans.indexOf(plan)

  const formattedPlan = Util.capitalize(plan)

  const content = (
    <div className="flex flex-col gap-5">
      <NextUI.Separator />

      <div className="flex flex-col gap-6">
        <NextUI.Text className="font-black">{listTitle}</NextUI.Text>
        <ul
          className={`flex list-inside flex-col gap-4 *:relative *:pl-6 *:before:absolute *:before:left-0 *:before:ps-2 *:before:content-['✓']`}
        >
          {children}
        </ul>
      </div>
    </div>
  )

  return (
    <div
      data-recommended={recommendedPlan === plan || undefined}
      aria-current={isCurrent || undefined}
      className={WebUI.cn(
        'flex min-w-[300px] flex-col gap-5 bg-trueWhite px-7 py-10 text-ds-sm data-[recommended]:bg-teal-200',
        className,
      )}
      {...restProps}
    >
      <div className="flex min-h-[214px] flex-col gap-5">
        <div className="flex flex-col gap-2">
          <NextUI.NextHeading className="text-h-5" as="h3">
            {formattedPlan}
          </NextUI.NextHeading>
          <NextUI.Text className="font-normal text-ds-2xl">
            {plan === 'partner' ? (
              <NextUI.Image
                className="h-[46px] max-h-[46px]"
                src={ManagersLogoIcon}
                alt=""
              />
            ) : (
              `$${{basic: 0, pro: 15, team: 35}[plan]}`
            )}
            {(plan === 'pro' || plan === 'team') && (
              <NextUI.Text className="ml-1 font-light text-ds-sm text-trueBlack">
                /month billed annually
              </NextUI.Text>
            )}
          </NextUI.Text>
        </div>
        <NextUI.Text>
          {
            {
              basic: 'Unlimited pages to collect payments, forms, and sign ups',
              pro: 'Advanced features that make selling and registrations easy',
              team: 'Boost engagement with lowest fees, a custom group website, and more',
              partner:
                'Custom templates and data insight for multi-tiered organizations',
            }[plan]
          }
        </NextUI.Text>
        <NextUI.NextButton
          size="md"
          variant={
            isDowngrade || (isCurrent && !planIsMonthly) ? 'gray' : 'orange'
          }
          disabled={isCurrent && !isPaused && !planIsMonthly}
          onClick={() => onPlanSelect?.()}
        >
          {(() => {
            if (isDowngrade) {
              return `Downgrade to ${formattedPlan}`
            }
            if (isCurrent) {
              if (isPaused) {
                return 'Reactivate Pro'
              }
              if (planIsMonthly) {
                return `Save with ${formattedPlan} (Annual)`
              }

              return 'Your Current Plan'
            }
            if (ctaButtonTitle) {
              return ctaButtonTitle
            }

            return `Go ${formattedPlan}`
          })()}
        </NextUI.NextButton>
      </div>

      {media.sm ? (
        content
      ) : (
        <WebUI.Disclosure className="contents">
          {(disclosure) => (
            <>
              <WebUI.DisclosureButton variant="link" arrow={false}>
                {`${disclosure.visible ? 'Hide' : 'View'} Features`}
              </WebUI.DisclosureButton>
              <WebUI.DisclosureContent className="contents">
                {content}
              </WebUI.DisclosureContent>
            </>
          )}
        </WebUI.Disclosure>
      )}
    </div>
  )
}

// MARK: FeesTooltip

interface FeesTooltipProps extends WebUI.TooltipAnchorProps {
  plan: PlanCardPlan
  creditCardFee: React.ReactNode
  eChecksFee?: React.ReactNode
}

const FeesTooltip = ({
  plan,
  creditCardFee,
  eChecksFee,
  className,
  ...restProps
}: FeesTooltipProps) => (
  <WebUI.Tooltip>
    <WebUI.TooltipAnchor
      className={WebUI.cn('inline text-teal-600', className)}
      {...restProps}
    />
    <WebUI.TooltipContent className="p-5 [text-align:inherit]" variant="light">
      <div className="flex flex-col">
        <WebUI.Text className="font-bold">
          {Util.capitalize(plan)} Plan Fees
        </WebUI.Text>
        <WebUI.Text className="font-light">
          Credit Cards: {creditCardFee}
        </WebUI.Text>
        {eChecksFee && (
          <WebUI.Text className="font-light">eChecks: {eChecksFee}</WebUI.Text>
        )}
      </div>
    </WebUI.TooltipContent>
  </WebUI.Tooltip>
)

// MARK: – Helpers

function getPlanCardPlan(session: Api.Session): PlanCardPlan {
  if (session.partner_admin.access) {
    return 'partner'
  }
  if (session.capabilities.plan === 'pause') {
    return 'pro'
  }
  if (session.capabilities.plan === 'free') {
    return 'basic'
  }
  return session.capabilities.plan
}

function isMonthlyPlan(planName: string) {
  return planName === 'Team (Monthly)' || planName === 'PRO (Monthly)'
}

export default PlansPage
