import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'

const prefabContextSchema = z.object({
  user_uuid: z.string().optional(),
  tab_slug: z.string().optional(),
  key: z.string(),
})

export default {
  getConfig: makeEndpoint({
    path: 'prefab/config',
    method: 'GET',
    queryParamsSchema: prefabContextSchema,
    responseSchema: z.object({
      value: z.any(),
    }),
  }),
  featureEnabled: makeEndpoint({
    path: 'prefab/feature',
    method: 'GET',
    queryParamsSchema: prefabContextSchema,
    responseSchema: z.object({
      enabled: z.boolean(),
    }),
  }),
}
