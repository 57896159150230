import {api} from '@cheddarup/api-client'
import {
  templateCategoryToDescriptionMap,
  templateGroupTypeToDescriptionMap,
} from '@cheddarup/core'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useEffect, useRef} from 'react'
import {useParams} from 'react-router-dom'
import {Link} from 'src/components/Link'
import {TabTemplatesGrid} from 'src/components/TabTemplatesGrid'
import {StringParam, useQueryParam} from 'use-query-params'
import PageTitleHelmet from 'src/components/PageTitleHelmet'

const TemplatesPage = () => {
  const urlParams = useParams()
  const [searchTerm] = useQueryParam('q', StringParam)
  const [_orderBy, setOrderBy] = useQueryParam('order-by', StringParam)
  const media = WebUI.useMedia()

  const validOrderByValues: Api.TabTemplatesOrderBy[] = [
    'recentlyAdded',
    'mostUsed',
  ]
  const orderBy = validOrderByValues.includes(_orderBy ?? ('' as any))
    ? (_orderBy as Api.TabTemplatesOrderBy)
    : 'recentlyAdded'

  const templatesQuery = api.templates.list.useQuery(
    {
      queryParams: {
        orderBy: {
          key: orderBy,
        },
      },
    },
    {
      select: (templates) =>
        Util.sort(
          templates
            .filter(
              Util.fuzzyFilterIterator(searchTerm ?? '', {
                iterator: (template) => template.name,
              }),
            )
            .filter(
              (template) =>
                urlParams.filterType !== 'group-type' ||
                !urlParams.filterValue ||
                template.options.template.groups?.some(
                  (g) => g.type === urlParams.filterValue,
                ),
            )
            .filter(
              (template) =>
                urlParams.filterType !== 'category' ||
                !urlParams.filterValue ||
                template.options.template.categories?.some(
                  (c) => c.type === urlParams.filterValue,
                ),
            ),
        ).asc((template) => {
          if (
            !urlParams.filterValue ||
            (urlParams.filterType !== 'category' &&
              urlParams.filterType !== 'group-type')
          ) {
            return 0
          }

          const order = (
            template.options.template[
              urlParams.filterType === 'category' ? 'categories' : 'groups'
            ] as Array<Api.TabTemplateCategory | Api.TabTemplateGroup>
          ).find((catOrGr) => catOrGr.type === urlParams.filterValue)?.order

          return order ?? Number.MAX_SAFE_INTEGER
        }),
    },
  )

  const description =
    urlParams.filterValue &&
    (urlParams.filterType === 'group-type' ||
      urlParams.filterType === 'category')
      ? ((
          {
            'group-type': templateGroupTypeToDescriptionMap,
            category: templateCategoryToDescriptionMap,
          }[urlParams.filterType] as any
        )[urlParams.filterValue] as string | undefined)
      : null

  const categoryContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (media.sm) {
      window.scrollTo({top: 0, behavior: 'smooth'})
    } else {
      categoryContainerRef.current?.scrollIntoView({behavior: 'smooth'})
    }
  }, [media])

  const pageTitle = urlParams.filterValue
    ? `${urlParams.filterValue} - Templates | Cheddar Up - Collect Money for Groups Online`
    : undefined

  return (
    <>
      <PageTitleHelmet overrideTitle={pageTitle} />
      <WebUI.VStack
        ref={categoryContainerRef}
        className="items-center bg-trueWhite px-4 pt-12 pb-16"
      >
        <WebUI.VStack className="w-full max-w-[calc(1080px+2*theme(spacing.4))] gap-4">
          <Link
            variant="primary"
            to=".."
            iconBefore={<WebUI.PhosphorIcon icon="arrow-left" />}
          >
            View all
          </Link>

          {!!urlParams.filterValue && (
            <WebUI.VStack className="gap-1">
              <WebUI.Heading
                className="font-accentAlt text-ds-5xl leading-[54px]"
                as="h2"
              >
                {urlParams.filterValue}
              </WebUI.Heading>
              {!!description && (
                <WebUI.Text className="text-ds-md">{description}</WebUI.Text>
              )}
            </WebUI.VStack>
          )}

          <WebUI.VStack className="gap-6">
            <WebUI.HStack className="justify-end gap-3">
              <WebUI.HStack className="items-baseline gap-1 font-normal text-ds-sm">
                <span>Sort by:</span>
                <WebUI.DropdownSelectText
                  value={orderBy}
                  onValueChange={(newOrderBy) => {
                    if (newOrderBy != null) {
                      setOrderBy(newOrderBy)
                    }
                  }}
                >
                  <WebUI.DropdownSelectOption value="recentlyAdded">
                    Recently Added
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value="mostUsed">
                    Most Used
                  </WebUI.DropdownSelectOption>
                </WebUI.DropdownSelectText>
              </WebUI.HStack>
            </WebUI.HStack>
            <TabTemplatesGrid
              loading={templatesQuery.isLoading}
              templates={templatesQuery.data ?? []}
            />
          </WebUI.VStack>
        </WebUI.VStack>
      </WebUI.VStack>
    </>
  )
}

export default TemplatesPage
