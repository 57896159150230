import PageTitleHelmet from 'src/components/PageTitleHelmet'

export interface GroupPageHelmetProps {
  groupPage?: Api.GroupPage
}

const GroupPageHelmet = ({groupPage}: GroupPageHelmetProps) => {
  const tagLine = groupPage?.sections?.top_banner?.tagline
  const headline = groupPage?.sections?.top_banner?.headline
  const groupName = headline ? `${headline} - Cheddar Up` : 'Cheddar Up'

  return (
    <PageTitleHelmet overrideTitle={headline ? groupName : undefined}>
      {!!tagLine && [
        <meta key="description" property="description" content={tagLine} />,
        <meta
          key="og:description"
          property="og:description"
          content={tagLine}
        />,
        <meta
          key="twitter:description"
          property="twitter:description"
          content={tagLine}
        />,
      ]}
      <meta itemProp="name" content={groupName} />
    </PageTitleHelmet>
  )
}

export default GroupPageHelmet
