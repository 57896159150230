import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import type {
  QueryObserverSuccessResult,
  UseQueryResult,
} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {newGenericForwardRef} from '@cheddarup/react-util'

export interface QueryDataListOwnProps<
  T extends WebUI.ListRowData,
  L extends WebUI.AnyList<WebUI.DataListRow<T>>,
> {
  query: UseQueryResult<T[]>
  loadingRowsCount?: number
  children?: (
    dataList: Parameters<NonNullable<WebUI.DataListProps<T, L>['children']>>[0],
    query: QueryObserverSuccessResult<T[]>,
  ) => React.ReactNode
}

export type QueryDataListProps<
  T extends WebUI.ListRowData,
  L extends WebUI.AnyList<WebUI.DataListRow<T>>,
> = L extends React.ComponentType<infer P>
  ? Util.SimpleMerge<
      Omit<P, 'data' | 'RowComponent'>,
      Util.SimpleMerge<
        Omit<WebUI.DataListOwnProps<T>, 'data'>,
        QueryDataListOwnProps<T, L> & {
          ListComponent?: L
        }
      >
    >
  : never

export const QueryDataList = newGenericForwardRef(
  <T extends WebUI.ListRowData, L extends WebUI.AnyList<WebUI.DataListRow<T>>>({
    query,
    loadingRowsCount = 3,
    DataRowComponent,
    className,
    children,
    ref: forwardedRef,
    ...restProps
  }: QueryDataListProps<T, L> & {
    ref?: React.Ref<WebUI.DataListInstance>
  }) => {
    if (query.status === 'error') {
      return null
    }
    if (query.isLoading) {
      const AnyDataRowComponent = DataRowComponent as any
      return (
        <div
          className={WebUI.cn(
            'QueryDataList-loadingView',
            'flex h-full flex-[1] flex-col overflow-y-auto',
            className,
          )}
        >
          {Array.from({length: loadingRowsCount}).map((_, idx) => (
            <AnyDataRowComponent key={String(idx)} />
          ))}
        </div>
      )
    }
    if (query.status === 'success' || query.fetchStatus === 'idle') {
      return (
        <WebUI.DataList<T, L>
          ref={forwardedRef}
          className={className}
          DataRowComponent={DataRowComponent}
          data={query.data ?? []}
          {...(restProps as any)}
        >
          {children
            ? (dataList) =>
                children(dataList, query as QueryObserverSuccessResult<T[]>)
            : children}
        </WebUI.DataList>
      )
    }

    return <>{null}</>
  },
)

export interface QueryDataSectionListOwnProps<
  S extends WebUI.ListRowData,
  R extends WebUI.ListRowData,
> {
  query: UseQueryResult<Array<WebUI.SectionListData<S, R>>>
  loadingRowsCount?: number
}

export type QueryDataSectionListProps<
  S extends WebUI.ListRowData,
  R extends WebUI.ListRowData,
  L extends WebUI.AnySectionList<WebUI.DataListRow<S>, WebUI.DataListRow<R>>,
> = L extends React.ComponentType<infer P>
  ? Util.SimpleMerge<
      Omit<P, 'SectionComponent' | 'RowComponent' | 'data'>,
      Util.SimpleMerge<
        WebUI.DataSectionListOwnProps<S, R>,
        QueryDataSectionListOwnProps<S, R>
      >
    > & {
      SectionListComponent?: L
    }
  : never

export const QueryDataSectionList = newGenericForwardRef(
  <
    S extends WebUI.ListRowData,
    R extends WebUI.ListRowData,
    L extends WebUI.AnySectionList<WebUI.DataListRow<S>, WebUI.DataListRow<R>>,
  >({
    query,
    loadingRowsCount = 3,
    DataSectionComponent,
    DataRowComponent,
    className,
    ref: forwardedRef,
    ...restProps
  }: QueryDataSectionListProps<S, R, L> & {
    ref?: React.Ref<WebUI.SectionListInstance>
  }) => {
    if (query.status === 'error') {
      return null
    }
    if (query.isLoading) {
      const AnyDataSectionComponent = DataSectionComponent as any
      const AnyDataRowComponent = DataRowComponent as any
      return (
        <div
          className={WebUI.cn(
            'flex h-full flex-col overflow-y-auto',
            className,
          )}
        >
          {Array.from({length: loadingRowsCount}).map((_, idx) => (
            <AnyDataSectionComponent key={idx}>
              <div className="SectionContainer flex flex-col gap-2">
                {Array.from({length: 2}).map((_, jdx) => (
                  <AnyDataRowComponent key={jdx} />
                ))}
              </div>
            </AnyDataSectionComponent>
          ))}
        </div>
      )
    }
    if (query.status === 'success' || query.fetchStatus === 'idle') {
      return (
        <WebUI.DataSectionList<S, R, L>
          ref={forwardedRef}
          className={className}
          DataSectionComponent={DataSectionComponent}
          DataRowComponent={DataRowComponent}
          data={query.data ?? []}
          {...(restProps as any)}
        />
      )
    }

    return <>{null}</>
  },
)
