import React, {
  Reducer,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'

import useCart from '../hooks/useCart'
import usePublicCollection from '../hooks/usePublicCollection'
import {useIsAuthed} from 'src/hooks/useAuthToken'
import {BooleanParam, useQueryParam} from 'use-query-params'
import {api} from '@cheddarup/api-client'
import {useUpdateEffect} from '@cheddarup/react-util'
import CartHelpers from 'src/helpers/CartHelpers'

export type CheckoutFlowStep = 'auth' | 'address' | 'payment'

export interface CheckoutPageFlowContextValue {
  state: CheckoutPageFlowState
  actions: {
    proceed: () => void
    setActiveStep: (step: CheckoutFlowStep) => void
  }
}

export interface CheckoutPageFlowState {
  activeStep: CheckoutFlowStep
}

export type CheckoutPageFlowAction =
  | {
      type: 'SET_ACTIVE_STEP'
      value: CheckoutFlowStep
    }
  | {
      type: 'PROCEED_TO_NEXT_STEP'
    }

const CheckoutPageFlowContext = React.createContext(
  {} as CheckoutPageFlowContextValue,
)

export interface CheckoutPageFlowProviderProps {
  children: React.ReactNode
}

export const CheckoutPageFlowProvider = (
  props: CheckoutPageFlowProviderProps,
) => {
  const [addPayment] = useQueryParam('add-payment', BooleanParam)
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const steps = useCheckoutSteps()
  const isAuthed = useIsAuthed()
  const hasFullNumberQuery = api.auth.session.useQuery(undefined, {
    enabled: isAuthed,
    select: (session) => !!session.user.profile.phone.fullNumber,
  })

  const reducer: Reducer<CheckoutPageFlowState, CheckoutPageFlowAction> =
    useCallback(
      (state, action) => {
        switch (action.type) {
          case 'SET_ACTIVE_STEP':
            return {
              ...state,
              activeStep: action.value,
            }
          case 'PROCEED_TO_NEXT_STEP':
            return {
              ...state,
              activeStep:
                steps[steps.indexOf(state.activeStep) + 1] ??
                // biome-ignore lint/style/noNonNullAssertion:
                steps[steps.length - 1]!,
            }
          default:
            throw new Error(`action "${action}" is not supported`)
        }
      },
      [steps],
    )

  const isAuthRequired = !!cart && cart.recurringTotal > 0
  const isQuickCheckout =
    !CartHelpers.isPaymentRequired({cart}) &&
    !publicCollection.shippingOptions?.shipToEnabled

  const shipTo = cart?.shippingInfo.shipTo ?? null
  const isAddressStepComplete =
    !!publicCollection.shippingOptions?.shipToEnabled &&
    cart?.shippingInfo.shippingMethod !== 'localPickup' &&
    (!shipTo ||
      !shipTo.address ||
      !shipTo.city ||
      !shipTo.country ||
      !shipTo.name ||
      !shipTo.state ||
      !shipTo.zip)

  const isMemberInfoEmpty = !cart?.member?.email || !cart.member.name

  const hasFullNumber = !!cart?.phoneNumber || hasFullNumberQuery.data !== false

  const isAuthStepComplete = (() => {
    if (addPayment) {
      return !isMemberInfoEmpty
    }
    if (isQuickCheckout) {
      return isAuthed
    }

    return (
      (isAuthed && hasFullNumber) || (!isAuthRequired && !isMemberInfoEmpty)
    )
  })()

  const [state, dispatch] = useReducer(reducer, {
    activeStep: isAuthStepComplete
      ? isAddressStepComplete
        ? 'address'
        : 'payment'
      : 'auth',
  })

  useUpdateEffect(() => {
    if (!hasFullNumber) {
      dispatch({type: 'SET_ACTIVE_STEP', value: 'auth'})
    }
  }, [hasFullNumber])

  const contextValue: CheckoutPageFlowContextValue = useMemo(
    () => ({
      state,
      actions: {
        proceed: () => dispatch({type: 'PROCEED_TO_NEXT_STEP'}),
        setActiveStep: (value) => dispatch({type: 'SET_ACTIVE_STEP', value}),
      },
    }),
    [state],
  )

  return <CheckoutPageFlowContext.Provider value={contextValue} {...props} />
}

// MARK: – Helpers

export function useCheckoutSteps(): CheckoutFlowStep[] {
  const {publicCollection} = usePublicCollection()

  return useMemo(
    () =>
      [
        'auth',
        publicCollection.shippingOptions?.shipToEnabled ? 'address' : null,
        'payment',
      ].filter((step): step is CheckoutFlowStep => step != null),
    [publicCollection?.shippingOptions?.shipToEnabled],
  )
}

export function useActiveCheckoutStep() {
  const contextValue = useContext(CheckoutPageFlowContext)

  if (!contextValue?.state) {
    return 'address'
  }

  return contextValue.state.activeStep
}

export const useCheckoutFlowActions = () => {
  const contextValue = useContext(CheckoutPageFlowContext)
  return contextValue.actions
}
