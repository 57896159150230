// @ts-ignore
import {z} from '@cheddarup/util'

import {
  profilePhoneSchema,
  userProfileSchema,
  userUiClientFlagsSchema,
  withdrawalDataSchema,
} from './clients'
import {cheddarUpImageSchema, s3ImageSchema} from './common'
import {managerPermissionRoleSchema} from './managers'
import {subscriptionPlanTypeSchema} from './subscriptions'

export const stripeDataSchema = z.object({
  chargesEnabled: z.boolean(),
  payoutsEnabled: z.boolean(),
  bankModificationsDisabled: z.boolean(),
  fieldsNeeded: z.string().array(),
  stripePersonVerified: z.enum(['unverified', 'verified']),
})

export const userCapabilitiesSchema = z.object({
  plan: subscriptionPlanTypeSchema,
  subscribed_to_pro: z.boolean(),
  subscribed_to_team: z.boolean(),
  can_upload_catalogs: z.boolean(),
  shippingEnabled: z.boolean(),
})

export const collectionCreateRoleSchema = z.object({
  id: z.number(),
  name: z.string(),
  profile_pic: cheddarUpImageSchema.nullish(),
  profile: z.object({
    uiClientFlags: userUiClientFlagsSchema,
  }),
})

export const defaultStripeAccountSettingsSchema = z.object({
  company: z.object({
    name: z.string(),
  }),
})

export const partnerAdminSchema = z.object({
  org: z
    .object({
      id: z.number(),
      name: z.string(),
      canInviteCollectors: z.boolean(),
      defaultStripeAccountSettings:
        defaultStripeAccountSettingsSchema.nullish(),
    })
    .nullish(),
  access: z.boolean(),
  accessType: z.string(),
})

export const sessionAnalyticsSchema = z
  .object({
    cflow: z.boolean(),
    sib: z.record(z.any()),
  })
  .passthrough()

export const orgMetadataIdentifierSchema = z.object({
  enabled: z.boolean(),
  options: z.string().array(),
  required: z.boolean(),
})

export const userOrganizationSchema = z.object({
  catalogOrder: z.number().array(),
  id: z.number().nullish(),
  slug: z.string().nullish(),
  type: z.string().nullable(),
  name: z.string().nullish(),
  catalog_count: z.number(),
  internalMarketplace: z
    .object({
      enabled: z.boolean(),
      organizerNickname: z.string(),
    })
    .nullish(),
  logo: z.string(),
  setupCompleted: z.boolean(),
  disableSignup: z.boolean(),
  faqUrl: z.string().nullable(),
  howItWorksVideoUrl: z.string().nullable(),
  partnerBenefitsLink: z.string().nullable(),
  partnerUrl: z.string().nullable(),
  partnerDiscountText: z.string().nullable(),
  partner_admin: partnerAdminSchema,
  organizationIdentifier: orgMetadataIdentifierSchema.nullable(),
})

export const managerRolePermissionTypeSchema = z.enum([
  'all',
  'specific',
  'own',
])

export const managerRoleNotificationSchema = z.object({
  payments: z.boolean(),
  reminders: z.boolean(),
})

export const managerRolePermissionsSchema = z.object({
  create: z.boolean(),
  type: managerRolePermissionTypeSchema,
  role: managerPermissionRoleSchema,
  schema: z.string(),
  group_page: z.boolean().nullish(),
  address_book_and_message_center: z.boolean().nullish(),
  notifications: managerRoleNotificationSchema.optional(),
})

export const managerRoleSchema = z.object({
  id: z.number(),
  slug: z.string(),
  email: z.string(),
  name: z.string(),
  full_name: z.string(),
  canCreate: z.boolean(),
  profile_pic: s3ImageSchema.nullish(),
  profile: z.object({
    uiClientFlags: userUiClientFlagsSchema.nullish(),
    phone: profilePhoneSchema.optional(),
    returnAddress: z
      .object({
        address: z.string().optional(),
        city: z.string(),
        state: z.string(),
        country: z.string(),
        zip: z.string(),
      })
      .nullish(),
  }),
  group_page_logo: s3ImageSchema.nullish(),
  withdrawal_data: withdrawalDataSchema.optional(),
  permissions: managerRolePermissionsSchema,
  persona_completed: z.boolean(),
  persona_required: z.boolean(),
  fee_transparency: z.boolean(),
  managerRoleId: z.number(),
})

export const sessionAuthSchema = z.object({
  isAdmin: z.boolean(),
  impersonation: z.object({
    userIsImpersonated: z.boolean(),
  }),
})

export const sessionSchema = z.object({
  user: userProfileSchema,
  upvoty: z.object({
    token: z.string(),
  }),
  stripe_data: stripeDataSchema,
  capabilities: userCapabilitiesSchema,
  collection_create_roles: collectionCreateRoleSchema.array(),
  organization_data: userOrganizationSchema.nullish(),
  manager_roles: managerRoleSchema.array(),
  partner_admin: partnerAdminSchema,
  organization: z.string().nullish(),
  session: z.object({
    expires_in: z.number(),
    insecure: z.boolean().optional(),
    token: z.string().optional(),
  }),
  analytics: sessionAnalyticsSchema,
  auth: sessionAuthSchema.nullish(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type StripeData = z.infer<typeof stripeDataSchema>
    // @ts-ignore
    type SubscriptionPlanType = z.infer<typeof subscriptionPlanTypeSchema>
    // @ts-ignore
    type Session = z.infer<typeof sessionSchema>
    // @ts-ignore
    type ManagerRole = z.infer<typeof managerRoleSchema>
    // @ts-ignore
    type ManagerRolePermissions = z.infer<typeof managerRolePermissionsSchema>
    // @ts-ignore
    type ManagerRoleNotification = z.infer<typeof managerRoleNotificationSchema>
    // @ts-ignore
    type ManagerRolePermissionType = z.infer<
      typeof managerRolePermissionTypeSchema
    >
    // @ts-ignore
    type UserOrganization = z.infer<typeof userOrganizationSchema>
    // @ts-ignore
    type SessionAnalytics = z.infer<typeof sessionAnalyticsSchema>
    // @ts-ignore
    type PartnerAdmin = z.infer<typeof partnerAdminSchema>
    // @ts-ignore
    type UserCapabilities = z.infer<typeof userCapabilitiesSchema>
    // @ts-ignore
    type CollectionCreateRole = z.infer<typeof collectionCreateRoleSchema>
    // @ts-ignore
    type OrgMetadataIdentifier = z.infer<typeof orgMetadataIdentifierSchema>
  }
}
