export function isEveryPersonalDetailsFilled(user: Api.UserProfile): boolean {
  if (!user.dob || !user.ssn_last_4_provided) {
    return false
  }
  const isPersonalAddressFieldsFilled =
    user.personal_address.line1 &&
    user.personal_address.city &&
    user.personal_address.state &&
    user.personal_address.postal_code

  return !!isPersonalAddressFieldsFilled
}

export function isEveryOrgDetailsFilled(user: Api.UserProfile): boolean {
  if (!user.dob || !user.ssn_last_4_provided) {
    return false
  }
  const isOrgAddressFieldsFilled =
    user.business_address.line1 &&
    user.business_address.city &&
    user.business_address.state &&
    user.business_address.postal_code

  return !!isOrgAddressFieldsFilled && isEveryPersonalDetailsFilled(user)
}

export function isAccountDetailsFieldsFilled(user: Api.UserProfile): boolean {
  if (user.entity_type === 'individual') {
    return isEveryPersonalDetailsFilled(user)
  }
  return isEveryOrgDetailsFilled(user)
}

export function is2FAExpired(twoFactorVerifiedAt: number | null) {
  if (twoFactorVerifiedAt == null) {
    return true
  }

  const currentTimestampInSeconds = Math.floor(Date.now() / 1000)
  const lastVerifiedAtTimestampInSeconds = twoFactorVerifiedAt
  const deltaInMinutes =
    (currentTimestampInSeconds - lastVerifiedAtTimestampInSeconds) / 60

  return deltaInMinutes >= 20
}
