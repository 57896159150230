import {api} from '@cheddarup/api-client'
import {getPayerBrandKitColors} from '@cheddarup/core'
import * as WebUI from '@cheddarup/web-ui'
import {NextLink} from 'src/components/__next/Link'
import {Link} from 'src/components/Link'
import {LinkButton} from 'src/components/LinkButton'
import {SharpImage} from 'src/components/SharpImage'
import {ColorRow} from 'src/views/group/GroupPage/GroupPage'

const BrandingUpsellPanel = () => {
  const {data: branding} = api.userBrandings.detail.useQuery()

  const brandingEnabled =
    branding?.color_palette?.payerPage?.enabled || branding?.logo
  const brandKitColors = getPayerBrandKitColors(
    branding?.color_palette?.payerPage,
  )

  return (
    <WebUI.Panel className="px-7 py-6">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-3">
          <WebUI.Text className="text-ds-md">Branding</WebUI.Text>
          <WebUI.Text className="font-light">
            Replace Cheddar Up’s logo at the top of all of your pages and
            customize button colors within your{' '}
            <Link
              variant="primary"
              className="[&_>_.Text]:font-light"
              to="my-account/brand-kit-logo"
            >
              Brand Kit
            </Link>
            .
          </WebUI.Text>
        </div>
        {brandingEnabled && (
          <>
            {branding.color_palette?.payerPage?.enabled && (
              <div className="flex flex-col gap-3">
                <Link className="block" to="my-account/brand-kit-palette">
                  <ColorRow color={brandKitColors.primaryButton}>
                    Primary
                  </ColorRow>
                </Link>
                <Link className="block" to="my-account/brand-kit-palette">
                  <ColorRow color={brandKitColors.secondaryButton}>
                    Secondary
                  </ColorRow>
                </Link>
              </div>
            )}
            {branding.enable_brand_logo && branding.logo && (
              <>
                <WebUI.Separator />
                <NextLink to="my-account/brand-kit-logo">
                  <SharpImage
                    image={branding.logo.url}
                    alt="Custom logo"
                    className="max-h-13 w-fit"
                  />
                </NextLink>
              </>
            )}
          </>
        )}
        {!brandingEnabled && (
          <LinkButton
            variant="default"
            className="w-40 self-start"
            to="my-account/brand-kit-logo"
          >
            Add Branding
          </LinkButton>
        )}
      </div>
    </WebUI.Panel>
  )
}

export default BrandingUpsellPanel
