import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageContainer} from 'src/components/PageContainer'
import ContactPage from 'src/views/contact/ContactPage'
import NotFoundPage from 'src/views/NotFoundPage'
import ThankYouPage from 'src/views/ThankYouPage'
import {WithAppHeaderLayout} from 'src/components/WithAppHeaderLayout'
import {TemplatesLayout} from 'src/views/templates/TemplatesLayout'
import TemplatesOverviewPage from 'src/views/templates/TemplatesOverviewPage'
import TemplatesPage from 'src/views/templates/TemplatesPage'
import {EnsureAuth} from 'src/hooks/useAuthToken'
import TemplatePreviewPage from 'src/views/templates/TemplatePreviewPage'

import {authRoutes} from './AuthRoutes'
import {collectionRoutes} from './CollectionRoutes'
import {homeRoutes} from './HomeRoutes'
import {EnsureGroupPage, getMeRoutes} from './MeRoutes'
import {guestRoutes} from './GuestRoutes'
import {PdfRoutes} from './PdfRoutes'
import PayerRouter from '../views/c'
import MarketplaceHomePage from '../views/marketplace/MarketplaceHomePage'
import MarketplaceShopItemsPage from '../views/marketplace/MarketplaceShopItemsPage'
import MarketplaceSalesPage from '../views/marketplace/MarketplaceSalesPage'
import MarketplaceMySalesPage from '../views/marketplace/MarketplaceMySalesPage'
import MePage from '../views/MePage'
import ReportDevicePage from '../views/ReportDevicePage'

export const RootRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/collections" />} />

    <Route path="pdf/*" element={<PdfRoutes />} />
    <Route
      path="contact/*"
      element={
        <PageContainer>
          <WithAppHeaderLayout>
            <ContactPage />
          </WithAppHeaderLayout>
        </PageContainer>
      }
    />
    <Route path="thankyou" element={<ThankYouPage />} />
    <Route path="report-device" element={<ReportDevicePage />} />

    <Route
      path="collection/*"
      element={
        <EnsureAuth>
          <Outlet />
        </EnsureAuth>
      }
    >
      {collectionRoutes}
    </Route>

    <Route path="guest">{guestRoutes}</Route>

    <Route
      path="marketplace/*"
      element={
        <EnsureAuth>
          <PageContainer>
            <Routes>
              <Route path="*" element={<MarketplaceHomePage />} />
              <Route
                path="shop-items/*"
                element={
                  <PageContainer>
                    <MarketplaceShopItemsPage />
                  </PageContainer>
                }
              />
              <Route path="sales" element={<MarketplaceSalesPage />} />
              <Route path="my-sales" element={<MarketplaceMySalesPage />} />
            </Routes>
          </PageContainer>
        </EnsureAuth>
      }
    />

    <Route
      path="templates/*"
      element={
        <PageContainer>
          <WithAppHeaderLayout>
            <TemplatesLayout>
              <Outlet />
            </TemplatesLayout>
          </WithAppHeaderLayout>
        </PageContainer>
      }
    >
      <Route index element={<TemplatesOverviewPage />} />
      <Route
        path=":filterType/:filterValue/*"
        element={
          <>
            <TemplatesPage />
            <Outlet />
          </>
        }
      >
        <Route path="c/:tabSlug/*" element={<TemplatePreviewPage />} />
      </Route>

      <Route path="c/:tabSlug/*" element={<TemplatePreviewPage />} />
    </Route>

    <Route
      path="me/:user/*"
      element={
        <EnsureGroupPage>
          <PageContainer>
            <MePage />
            <Outlet />
          </PageContainer>
        </EnsureGroupPage>
      }
    >
      {getMeRoutes(true)}
    </Route>

    <Route path="c/:tabSlug/*" element={<PayerRouter />} />

    <Route path="">
      {authRoutes}
      {homeRoutes}
    </Route>

    <Route path="*" element={<NotFoundPage />} />
  </Routes>
)
